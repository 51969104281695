import React, {useContext, useRef, useState} from 'react';
import { Link as RouterLink } from "react-router-dom";
import { RFTextField, Typography, required, UserContext, AjaxRequest } from "muistarter";
import {Field} from "react-final-form";
import FileList  from "./FileList";
import FileUploadForm from "./FileUploadForm";
import { standards, output_formats } from "./HexDecoder";
import {checkOutputFormatNeedsStandard} from "../utils";
import {SubscriptionContext} from "../contexts";
import Link from '@mui/material/Link';
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box"
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import LoopIcon from '@mui/icons-material/Loop';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SelectField from "./SelectField";
import { nowToString } from '../utils'

const fileDecodeUrl = process.env.REACT_APP_FILE_DECODE_URL;
const refreshRequestsInterval = parseInt(process.env.REACT_APP_DECODE_REQUEST_REFRESH_INTERVAL) * 1000;


const validate = (values) => {
    const errors = required(['request_type', 'output_format', 'standard', 'output_filename', 'files'], values);
    if (!errors.standard) {
    const standardError = checkOutputFormatNeedsStandard(values);
    if (standardError) {
        errors.standard = standardError;
        }
    }
    return errors;
 };



const getAdditionalFileInfo = (file, user) => {
    if (file.error) return `Error: ${file.error}`;
    if (file.completed_on) return 'Completed'
    if (file.processed_on) return "Decoding"
    if (file.queued_on) return "Queued"
    return ''
}


const getOutputFilename = (values) => {
    const dateString = nowToString();
    let output_format;
    if (values.output_format === 'yaml') output_format = 'DECODED'
    else output_format = values.output_format.toUpperCase();
    return `${values.standard}_${output_format}_${dateString}.txt`
}


const getUploadSuccessMessage = (request, responseData, msg) => {
    let typicalProcessingTime = responseData.typical_processing_time;
    if (typicalProcessingTime) {
        let interval = 'second';
        if (typicalProcessingTime > 50) {
            typicalProcessingTime = Math.round(typicalProcessingTime / 60);
            interval = 'minute';
        } else typicalProcessingTime = Math.round(typicalProcessingTime);
        let avtMessage = `${typicalProcessingTime} ${interval}`;
        if (typicalProcessingTime !== 1) avtMessage += 's';
        return `${msg}. Now Decoding. Requests of this size usually take around ${avtMessage} to complete.`;
    }
    return `${msg}. Now Decoding. Please wait.`;
}


const getIcon = (file) => {
    if (file.error) return ErrorIcon
    else if (file.completed_on) return CheckIcon
    else if (file.processed_on) return LoopIcon
    else return HourglassEmptyIcon
}


const FileDecoder = props => {

    let { fullScreen, initialValues = {}, openRequestsInterval, preferences = {}, setPreferences, storeAdditionalParams,
        request_type, analyticsRemoveEventArgs, analyticsListEventArgs, children, ...formProps} = props;

    initialValues = {
        request_type: request_type,
        standard: preferences.standard || 'AUTO_DETECT_HI',
        output_format: preferences.output_format || 'yaml',
        ...initialValues,
    }
    initialValues.output_filename = getOutputFilename(initialValues);
    const outputFilename = useRef(initialValues.output_filename);

    const [ajaxKey, setAjaxKey] = useState(0);
    const [intervalState, setInterval] = useState(refreshRequestsInterval);
    const [requests, setRequests] = useState([]);
    const [noImmediateRequest, setNoImmediateRequest] = useState(false);

    const user = useContext(UserContext).user;
    const subscriptionDetails = useContext(SubscriptionContext);
    const subscribed = subscriptionDetails.subscribed;
    const remaining = subscriptionDetails.remaining;
    const checked = subscriptionDetails.checked;
    const checkedForCurrentUser = subscriptionDetails.checkedForCurrentUser;

    const refresh = (interval) => {
        setNoImmediateRequest(false);
        setInterval(interval || intervalState);
        setAjaxKey(ajaxKey + 1);
    }

    const onRequestCreate = (values, form, request) => {
        refresh(openRequestsInterval);
        let newPreferences = ({standard: values.standard, output_format: values.output_format})
        if (storeAdditionalParams) newPreferences = {...newPreferences, ...storeAdditionalParams(newPreferences, request.data)};
        setPreferences(newPreferences);
        outputFilename.current = getOutputFilename(values);
        form.change('output_filename', outputFilename.current);
    }

    const changeInterval = (newInterval) => {
        setTimeout(() => {
            setInterval(newInterval);
            setAjaxKey(ajaxKey + 1);
            setNoImmediateRequest(true)
        }, 0);
    }

    const onRequestsRetrieved = async(response) => {
        const requests = response.results;
        setRequests(requests);

        let openRequests = false;
        for (let i = 0; i < requests.length; i++){
            let request = requests[i];
            if (!request.completed_on){
                openRequests = true;
                break;
            }
        }
        if (openRequests && intervalState !== openRequestsInterval) {
            changeInterval(openRequestsInterval);
        }else if (!openRequests && intervalState !== refreshRequestsInterval){
            changeInterval(refreshRequestsInterval);
        }
    }

    const onFormChange = (values, form) => {
        if (values.standard && values.output_format && values.output_filename === outputFilename.current){
            outputFilename.current = getOutputFilename(values);
            setTimeout(() => form.change('output_filename', outputFilename.current), 0);
        }
    }

    const openBackdrop = (checked, user, checkedForCurrentUser) => {
        return (!checked || (Boolean(user) && !checkedForCurrentUser))
    }

    let remainingRequests;
    if (remaining) remainingRequests = Boolean(remaining[request_type]);
    else remainingRequests = false;
    const canCreate = user && (subscribed || remainingRequests);

    return (
            <Grid container>
                <Grid item xs={12} md={fullScreen ? 6 : 12}>
                {user && canCreate &&
                <FileUploadForm url={fileDecodeUrl} method="POST" validate={validate}
                                onUpload={onRequestCreate} buttonText="Decode" noTopPadding
                                initialValues={initialValues} onValuesChange={onFormChange}
                                showPreviewsInDropzone showFileNames getUploadSuccessMessage={getUploadSuccessMessage}
                                {...formProps}>
                    <Field
                        component={SelectField}
                        label="Standard"
                        name="standard"
                        options={standards}
                        required
                        margin="dense"
                    >
                    </Field>
                    <Field
                        component={SelectField}
                        label="Output Format"
                        name="output_format"
                        options={output_formats}
                        required
                        margin="normal"
                    >
                    </Field>
                    <Field
                        component={RFTextField}
                        fullWidth
                        label="Output Filename"
                        name="output_filename"
                        required
                        margin="normal"
                    />
                    {children}
                </FileUploadForm>}
                {checked && !canCreate && <div id="not-subscribed-text">
                    {user && checkedForCurrentUser && <Typography>
                        You need a subscription to use this feature. Click <Link id="to-subscriptions" component={RouterLink} to="/subscriptions">here</Link> to subscribe.
                    </Typography>}
                    {!user && <Typography>
                        You need to sign in to use this feature. Click <Link id="to-signup" component={RouterLink} to="/sign-up">here</Link> to sign up. New users get a free request and can then click subscribe to check the subscription options.
                    </Typography>}
                </div>}
                <Backdrop open={openBackdrop(checked, user, checkedForCurrentUser)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                </Grid>
                {user && <Grid item xs={12} md={fullScreen ? 6 : 12}>
                    <FileList id="request-list" files={requests}
                              hasRemovePermission={(user, file) => Boolean(file.completed_on)}
                              showAdditionalInfo={getAdditionalFileInfo}
                              onFilesRemoved={refresh}
                              url={fileDecodeUrl}
                              getIcon={getIcon}
                              desc="Decode request"
                              analyticsRemoveEventArgs={analyticsRemoveEventArgs}
                    />
                    {requests && requests.length > 0 && <Box ml={fullScreen ? 6: 0} mt={6}><Typography>
                        When a request is completed, click it to open the result in a new tab or right-click to download
                    </Typography></Box>}
                    <AjaxRequest key={ajaxKey} method="GET" url={fileDecodeUrl} runAtInterval={intervalState}
                                 values={{request_type: request_type}} onSuccess={onRequestsRetrieved}
                                 noImmediateRequest={noImmediateRequest} showBackdrop
                                 analyticsEventArgs={analyticsListEventArgs}
                    />
                </Grid>}
            </Grid>)
}


export default FileDecoder;

import React from "react";
import FileUploadForm from "./FileUploadForm";


const FileUploadArea = props => {

    return <FileUploadForm text={"Drag and drop or click to add an attachment"} uploadOnChange
        noSubmitButton noTopPadding {...props}/>

}

export default FileUploadArea;

import React, {useState} from "react";
import PropTypes from 'prop-types';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { AjaxRequest, pageViewAnalytics, sendAnalyticsModalView, Paper } from "muistarter";


const AjaxConfirmationDialog = props => {

   const { title, open, onClose, onCancel, dialogTitle, dialogText, classes, ...ajaxRequestProps } = props;
   const [okClicked, setOkClicked] = useState(false);

   const onCloseDialog = (event, reason) =>{
        onClose && onClose();
        pageViewAnalytics();
    }

   const handleOK = async () => {
        setOkClicked(true);
        onCloseDialog();
    }

   const onCancelDialog = () => {
       onCancel && onCancel();
       onCloseDialog();
    }

   const onEntering = () => {
        sendAnalyticsModalView(title);
    }

   return (
    <div>
      {okClicked && <AjaxRequest showBackdrop {...ajaxRequestProps}/>}
      <Dialog
        open={open}
        onClose={onCloseDialog}
        TransitionProps={{onEnter: onEntering}}
        aria-labelledby={title}
        PaperComponent={Paper}
      >
        <div id={title}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id={`${title}-cancel`} autoFocus onClick={onCancelDialog} color="primary">
            Cancel
          </Button>
          <Button id={`${title}-ok`} onClick={handleOK} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
          </div>
      </Dialog>
    </div>
  );
}


AjaxConfirmationDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    dialogTitle: PropTypes.string,
    dialogText: PropTypes.string,
}


export default AjaxConfirmationDialog;


import React, {useContext} from 'react';
import DownloadList from "./DownloadList";
import Licenses from "./Licenses";
import {Typography, UserContext} from "muistarter";
import Box from "@mui/material/Box";


const rootSx = {
    width: {xs: "100%", md: 'unset'},
    marginTop: {md: 3},
    maxWidth: {md: "100%", xl: "80%"},
    marginLeft: {lg: 3, xl: 6},
    marginRight: {lg: 3, xl: 6},
}


const Downloads = () => {
    const user = useContext(UserContext).user;

    return (
        <>
            <Box sx={rootSx}>
                <DownloadList/>
            </Box>
            <Box sx={rootSx}>
                    {user ? <Licenses/> :
                        <Typography color="text.primary" id="no-subscription-text">You need an active subscription to generate
                            licenses for these products.</Typography>}
            </Box>
        </>
    )
}


Downloads.propTypes = {
}

export default Downloads;

import React, {useContext} from 'react';
import {Navigate} from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {SubscriptionContext} from "../contexts"


const RedirectToPricesOnUnSubscribed = () => {
    const subscriptionDetails = useContext(SubscriptionContext);
    const subscribed = subscriptionDetails.subscribed;
    const checkedForCurrentUser = subscriptionDetails.checkedForCurrentUser;

    return (
        <>
            {checkedForCurrentUser ?
                <Navigate to={subscribed ? "/" : "/subscriptions"}/>
                : <Backdrop open={true}><CircularProgress color="inherit"/></Backdrop>}
        </>
    )
}

RedirectToPricesOnUnSubscribed.propTypes = {
}



export default RedirectToPricesOnUnSubscribed;
import React, {useContext, useState} from 'react';
import { AjaxRequest, UserContext, sendAnalyticsOutboundLink, Typography } from "muistarter";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {styled} from '@mui/material/styles';
import Container from '@mui/material/Container';
import {SubscriptionContext} from "../contexts";
import {Navigate} from "react-router-dom";
import {capitalize} from "@mui/material/utils";
import AjaxConfirmationDialog from "./AjaxConfirmationDialog";
import {any} from "../utils";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";



const StripePCIComplianceLink = process.env.REACT_APP_STRIPE_PCI_COMPLIANCE_LINK


const linkSx = {
    color: "text.link"
}


const StyledList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
})

const heroContent = (theme) => ({
    padding: {xs: theme.spacing(0, 0, 2), xl: theme.spacing(0, 0, 6)}
})

const cardHeader = {
    backgroundColor: 'secondary.dark',
    color: 'secondary.light'
}

const cardContent = {
    backgroundColor: 'secondary.light',
}


const cardPricing = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: 2,
}

const cards = {
    paddingBottom: {xs: 3, sm: 3}
}

const bottomText = {
    paddingTop: {xs: 3, sm: 3}
}


const subscriptionUrl = process.env.REACT_APP_SUBSCRIPTION_URL;
const pricesUrl = process.env.REACT_APP_PRICES_URL;
const description = [
      'Offline Decoder Tools',
      'Unlimited Licenses',
      'Unlimited Online Decoding',
      'Priority Issue Support'
]


const PriceCard = props => {

    const { price, refresh, stripe, hasPaidSubscription } = props;

    const [checkout, setCheckout] = useState(false);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);

    const values = {price_id: price.id};

    const userDetails = useContext(UserContext);
    const user = userDetails.user;

    const subscriptionDetails = useContext(SubscriptionContext);
    const refreshSubscription = subscriptionDetails.refreshSubscription;

    const redirectToCheckout = responseData => {
        sendAnalyticsOutboundLink('https://checkout.stripe.com/pay/');
        stripe.redirectToCheckout(responseData);
    }

    const toggleCancelDialog = () => {
        setCancelDialogIsOpen(!cancelDialogIsOpen);
    }

    const toggleCheckout = () => {
        setCheckout(!checkout)
    }

   const refreshPrices = () => {
        refresh();
        refreshSubscription();
    }

    return (
        <>
            {checkout && user &&
                <AjaxRequest url={subscriptionUrl} method="POST" values={values} onSuccess={redirectToCheckout} showBackdrop
                    analyticsEventArgs={{category: 'Stripe', action: `Get Stripe Checkout SessionID for price: ${values.price_id}`}}>
                </AjaxRequest>
            }
                {checkout && !user && <Navigate to='/sign-up' />}
            <Box width={{xs: "100%", sm: "75%", md: "unset"}}>
                  <Card>
                            <CardHeader
                                title={price.metadata.price_header}
                                titleTypographyProps={{align: 'center'}}
                                subheaderTypographyProps={{align: 'center'}}
                                sx={cardHeader}
                            />
                            <CardContent sx={cardContent}>
                                <Box sx={cardPricing}>
                                    <Typography component="h2" variant="h3" color="secondary">
                                         €{price.unit_amount/ 100}
                                    </Typography>
                                    <Typography variant="h6" color="primary">
                                        {price.recurring ? `/${capitalize(price.recurring.interval)}`: ''}
                                    </Typography>
                                </Box>
                                <StyledList>
                                    {description.concat([price.metadata.additional_info]).map((line) => (
                                        <Typography component="li" variant="subtitle1" align="center"
                                                    key={line} color="primary">
                                            {line}
                                        </Typography>
                                    ))}
                                </StyledList>
                            </CardContent>
                            <CardActions sx={cardContent}>
                            {!hasPaidSubscription &&
                                <Button id={`subscribe-${price.id}`} fullWidth variant="contained" color="secondary"
                                onClick={toggleCheckout}>Subscribe Now</Button>}
                            {price.subscribed &&
                                <Button id={`cancel-price-${price.id}`} fullWidth variant="contained" color="secondary"
                                onClick={toggleCancelDialog}>Cancel Subscription</Button>}
                            </CardActions>
                  </Card>
                </Box>
                <AjaxConfirmationDialog open={cancelDialogIsOpen}
                                            onSuccess={() => refreshPrices(refresh)}
                                            onClose={toggleCancelDialog}
                                            method="DELETE"
                                            url={subscriptionUrl}
                                            title={`cancel-price-dialog-${price.id}`}
                                            dialogTitle='Cancel Subscription'
                                            dialogText="Are you sure you want to cancel your subscription?"
                                            successMessage="You have unsubscribed. Your final payment will be at the end of the current period. Sorry to see you go. :("
                />
            </>
        )
}


PriceCard.propTypes = {
    hasPaidSubscription: PropTypes.bool,
    price: PropTypes.object,
    refresh: PropTypes.func,
    stripe: PropTypes.object
}


const Pricing = (props) => {

    const [ajaxKey, setAjaxKey] = useState(0);
    const [prices, setPrices] = useState([]);

    const refresh = () => {
        setAjaxKey(ajaxKey + 1);
    }

    const onPricesRetrieved = responseData => {
        setPrices(responseData);
    }

    let priceProps = props;
    const hasPaidSubscription = any(prices, obj => obj.subscribed);

    return (
        <>
            <AjaxRequest key={ajaxKey} method="GET" url={pricesUrl} onSuccess={onPricesRetrieved}
                     analyticsEventArgs={{category: 'Subscriptions', action: 'List subscription prices'}} showBackdrop/>
            {/*<CssBaseline/> */}
            <Container component="main" sx={heroContent}>

            </Container>
            <Container maxWidth="md" component="main" sx={cards}>
                    <Typography component="h1" variant="h2" align="left" gutterBottom>
                    SUBSCRIPTIONS
                </Typography>
               <Typography variant="body1" align="left" component="p" sx={cards}>
                    To subscribe, please choose your preferred pricing interval and features:
                </Typography>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 2}}  id="price-list" justifyContent="center" alignItems="center" >
                    {prices.map((price, index) => (
                        <PriceCard price={price} key={index} {...priceProps}
                                   hasPaidSubscription={hasPaidSubscription} refresh={refresh}/>
                    ))}
                </Stack>
                  <Typography variant="body1" align="left" component="p" sx={bottomText}>
                    Our partners at <Link sx={linkSx} href={StripePCIComplianceLink} target="blank">Stripe</Link> handle all billing and charging of credit cards and are fully PCI compliant. Receipts will be sent to your registered e-mail for every payment.
                </Typography>
                <Typography variant="body1" align="left" component="p" sx={bottomText}>
                      To discuss alternative payment methods and subscription periods, or discounted team subscriptions, please contact us by opening a ticket under support while signed in.
                </Typography>
            </Container>

        </>
    )
}


Pricing.propTypes = {
}



export default Pricing;

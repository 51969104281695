import React from "react";
import FileDecoder from "./FileDecoder";


const filesLimit = parseInt(process.env.REACT_APP_MAX_SIMULTANEOUS_BINARY_FILES || 100);
const refreshOpenBinaryRequestsInterval = parseInt(process.env.REACT_APP_DECODE_REQUEST_REFRESH_INTERVAL_ON_OPEN_BINARY_REQUEST) * 1000;
const maxFileSize = parseInt(process.env.REACT_APP_BINARY_ENCODED_MAX_SIZE_MB) * 1024 * 1024;


const FileDecoderBinary = props => {
    return (
        <FileDecoder request_type="binary_files" maxFileSize={maxFileSize} formID="binary-decoder-form"
            openRequestsInterval={refreshOpenBinaryRequestsInterval} filesLimit={filesLimit} gaAction="Get Binary Requests"
        analyticsEventArgs={{category: 'File Decoder', action: 'Create Binary Request'}}
        analyticsRemoveEventArgs={{category: 'File Decoder', action: 'Remove Binary Request'}}
        analyticsListEventArgs={{category: 'File Decoder', action: 'List Binary Requests'}} {...props}/>
    )
}

export default FileDecoderBinary

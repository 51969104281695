import React, {useEffect, useContext, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AjaxForm, Paper, RFTextField, UserContext, Typography } from "muistarter";
import Grid from '@mui/material/Grid';
import capitalize from "@mui/material/utils/capitalize";
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Divider from "@mui/material/Divider"
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EmailIcon from '@mui/icons-material/Email';
import SearchIcon from '@mui/icons-material/Search';
import { Field } from 'react-final-form'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CommentIcon from '@mui/icons-material/Comment';
import Badge from '@mui/material/Badge';
import {ProductChip, ClosedChip, NoOwnerChip, AssignedToChip} from "./Chips";
import {formatDate} from "../utils";



const issueURL = process.env.REACT_APP_ISSUE_URL
const pageSize = parseInt(process.env.REACT_APP_ISSUE_PAGE_SIZE)
const refreshInterval = parseInt(process.env.REACT_APP_ISSUE_LIST_REFRESH_INTERVAL) * 1000


const issueListSx = {
    root: {
        width: {xs: "100%", md: "75%"},
        marginLeft: {md: 6},
        marginTop: {md: 3},
    },
    paper: {
        paddingBottom: {md: 3},
        minHeight: {xs: "75vh", md: "unset"}
    },
    topButton: {
        marginTop: 1,
    },
    issueList: {
        display: 'flex',
        width: "80%",
        backgroundColor: "secondary.light"
    },
    pagination: {
        color: "primary.main"
    },
    fonts: {
        fontWeight: "bold"
    },
    inline: {
        display: "inline"
    },
    alignRight: {
        textAlign: "right"
    }
}


const IssueList = props => {

    const defaultParams = {page_size: pageSize, page: 1}

    const [params, setParams] = useState(defaultParams);
    const [issues, setIssues] = useState([]);
    const [count, setCount] = useState(0);

    const submitForm = () => {
        document.getElementById('issueListForm').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }

    useEffect(submitForm, [params])

    const onPageChange = async(event, page) => {
        const newParams = {...params, page: page};
        setParams(newParams);
    }

    const adaptRequest = request => {
        request.params.page_size = params.page_size;
        if (request.params.search !== params.search) request.params.page = 1;
        else request.params.page = params.page;
        return request;
    }

    const handleResult = (data, form, request) => {
        setIssues(data.results);
        setCount(data.count);
    }

    const user = useContext(UserContext).user;

      return (
           <Box sx={issueListSx.root}>
                <Paper sx={issueListSx.paper}>
                    <Grid container>
                        <Grid item xs={5}>
                    <AjaxForm formID="issueListForm" url={issueURL} method="GET"
                                  onSuccess={handleResult} noSubmitButton runAtInterval={refreshInterval}
                                  adaptRequest={adaptRequest} showBackdrop
                                  analyticsEventArgs={{category: 'Issues', action: 'List Issues'}}>
                          <Field
                               component={RFTextField}
                               name="search"
                               placeholder="Search"
                            />
                            <IconButton type="submit" aria-label="search">
                                  <SearchIcon />
                            </IconButton>
                    </AjaxForm>
                        </Grid>
                          <Grid item xs={6} md={5}>
                              <Box sx={issueListSx.alignRight}>
                            <Button sx={issueListSx.topButton}
                            id="add-issue"
                            component={RouterLink} to="/issues/create"
                            color="secondary"
                            variant="contained"
                        >
                        CREATE NEW ISSUE
                     </Button>
                        </Box>
                        </Grid>
                    </Grid>
                <List sx={issueListSx.issueList} id="issue-list">
                    <Grid container direction="column">
                    {issues.map(issue => {
                        return (
                            <Grid item key={issue.slug}>
                                <ListItemButton key={issue.slug} id={`issue-link-${issue.slug}`} component={RouterLink} to={`/issues/view/${issue.slug}`}>
                                   <Grid container direction="row" spacing={1} alignItems="center" justify="flex-start">
                                       <Grid item>
                                    <ListItemText
                                        primary={
                                            <Typography sx={issueListSx.fonts}>
                                                    {issue.title}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={issueListSx.inline}
                                                    color="text.link"
                                                >
                                                    {capitalize(formatDate(issue.created_on))}
                                                </Typography>
                                                {user && user.is_staff && issue.user && ` - ${issue.user.name}`}
                                            </>
                                        }
                                    />
                                    </Grid>
                                    <Grid item>
                                    <ProductChip product={issue.product} issueSlug={issue.slug}/>
                                    </Grid>
                                    {user && user.is_staff && !issue.owner && !issue.closed_by && <Grid item><NoOwnerChip issueSlug={issue.slug}/></Grid>}
                                    {user && user.is_staff && issue.owner && issue.owner.id === user.id && <Grid item><AssignedToChip issueSlug={issue.slug} owner="You"/></Grid>}
                                    {user && Boolean(issue.unread_comments) && (!user.is_staff || issue.owner.id === user.id) &&
                                        <Grid item>
                                            <Tooltip title={`Issue has ${issue.unread_comments} unread comments`} aria-label="Unread comments">
                                         <Badge id={`unread-comments-${issue.slug}`} badgeContent={issue.unread_comments} color="secondary">
                                            <CommentIcon color="secondary"/>
                                         </Badge>
                                        </Tooltip>
                                        </Grid>}
                                    {user && !user.is_staff && issue.email_notifications &&
                                    <Grid item><Tooltip title="E-mail notifications enabled" aria-label="Email Notifications Enabled">
                                        <Badge color="secondary"><EmailIcon  color="secondary" id={`email-icon-${issue.slug}`} /></Badge>
                                    </Tooltip></Grid>}
                                    {issue.closed && <Grid item><ClosedChip issueId={issue.slug}/></Grid>}
                                    </Grid>
                                </ListItemButton>
                                <Divider/>
                            </Grid>
                        );
                    })}
                </Grid>
                </List>
        {count > params.page_size &&
        <Pagination color="primary" variant="text" id="issue-pagination" count={Math.ceil(count/params.page_size)}
                    page={params.page} onChange={onPageChange}
                    renderItem={
                        (item) => <PaginationItem sx={issueListSx.pagination} {...item} />
                    }
        />}
        </Paper>
        </Box>
  );
}


IssueList.propTypes = {

}


export default IssueList;
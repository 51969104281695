import React from 'react';
import { AppRoutes as AuthRoutes, ProtectedRoute } from "muistarter";
import IssueList from "./components/IssueList";
import IssueForm from "./components/IssueForm";
import IssueView from "./components/IssueView";
import IssueModify from "./components/IssueModify";
import Downloads from "./components/Downloads";
import {Route} from "react-router-dom";
import RedirectToPricesOnUnSubscribed from "./components/RedirectToPricesOnUnSubscribed";
import Pricing from "./components/PriceList";
import StripePortal from "./components/StripePortal";
import Home from "./components/Home";
import PropTypes from "prop-types";


const AppRoutes = props => {

    const { stripe } = props;

    return (
        <AuthRoutes redirectOnSignIn="/onsignin">
          <Route path="/issues/create"  element={<ProtectedRoute><IssueForm/></ProtectedRoute>}/>
          <Route path="/issues/view/:slug"  element={<ProtectedRoute><IssueView/></ProtectedRoute>}/>
          <Route path="/issues/modify/:slug"  element={<ProtectedRoute><IssueModify/></ProtectedRoute>}/>
          <Route path="/issues"  element={<ProtectedRoute><IssueList/></ProtectedRoute>}/>
          <Route path="/downloads" element={<Downloads/>}/>
          <Route path="/onsignin" element={<RedirectToPricesOnUnSubscribed/>}/>
          <Route path="/subscriptions" element={<Pricing stripe={stripe}/>}/>
          <Route path="/billing"  element={<ProtectedRoute><StripePortal/></ProtectedRoute>}/>
          <Route index element={<Home/>}/>
        </AuthRoutes>
    )
}

AppRoutes.propTypes = {
    stripe: PropTypes.object
}

export default AppRoutes;
import React, {useContext} from 'react';
import { AppBar as MuiStarterAppBar, UserContext } from "muistarter";
import {SubscriptionContext} from "../contexts";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MenuBookIcon from '@mui/icons-material/MenuBook';


const betaTestFormLink = process.env.REACT_APP_BETA_TEST_FORM_URL;
const docsLink = process.env.REACT_APP_DOCS_LINK;


const useGetAdditionalLinks = () => {
    const user = useContext(UserContext).user;
    const subscribed = useContext(SubscriptionContext).subscribed;

    let links = [
    {
        id: 'downloads',
        href: '/downloads',
        text: 'Downloads',
        icon: <CloudDownloadIcon/>
    },
    {
        id: 'documentation',
        href: docsLink,
        target: "_blank",
        text: 'User Guide',
        external: true,
        icon: <MenuBookIcon/>
    }
    ];
    if (user) {
        links.push({
            id: 'issues',
            href: '/issues',
            text: 'Support',
            icon: <ContactSupportIcon/>
        })
        links.push({
            id: 'manage-subscriptions',
            href: '/billing',
            text: 'Billing',
            icon: <ReceiptIcon/>
        })
    }
    if (!subscribed) {
        links.push({
            id: 'prices',
            href: '/subscriptions',
            text: 'Subscribe',
            icon: <SubscriptionsIcon/>
        })
        if (betaTestFormLink) {
            if (!user || !user.is_staff)
            links.push({
                id: 'request-free-access',
                href: betaTestFormLink,
                target: "_blank",
                text: 'Request Free Access',
                external: true,
                icon: <CloudCircleIcon/>
            })
        }
    }
    return links;
}


const AppBar = () => {
    const links = useGetAdditionalLinks();

    return (
        <MuiStarterAppBar additionalLeftTextLinks={()=>links}/>
    )
}


export default AppBar;
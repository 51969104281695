import { withRoot } from "muistarter"
// --- Post bootstrap -----
import React, {useEffect, useState} from 'react';
import {SetSubscriptionContext} from "./modules/contexts"
import SubscriptionAnnouncement from "./modules/components/Announcement";
import AppBar from "./modules/components/AppBar";
import AppRoutes from "./modules/AppRoutes"
import { VisibleApp, SetUserContext, Analytics, ToastMainContainer, GoogleIdentityOauth} from "muistarter";
import theme from "./modules/theme";
import {loadStripe} from "@stripe/stripe-js/pure";
import { GoogleOAuthProvider } from '@react-oauth/google';


const GoogleClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID
const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_test_51HVnLGCz06et8VuzOZl3Ys0rHWsIhTz7F9u1I7T9ipHkMNo8TOzW9Jlo36xJ1e0xe2u2OFyw6oSmC6RqMLZJNvkc00AwPxNLqb";


const App = () => {

   const [stripe, setStripe] = useState(null);

   useEffect(() => {
       loadStripe(stripeKey).then((stripe) => {
           setStripe(stripe);
       })
   }, [])

    return (
        <GoogleOAuthProvider clientId={GoogleClientId}>
          <SetUserContext>
          <GoogleIdentityOauth oneTap/>
          <SetSubscriptionContext>
          <Analytics/>
              <>
              <SubscriptionAnnouncement/>
              <VisibleApp footerProps={{title: "lidecoder.com"}}>
                <AppBar/>
                <ToastMainContainer/>
                <AppRoutes stripe={stripe}/>
              </VisibleApp>
              </>
          </SetSubscriptionContext>
          </SetUserContext>
        </GoogleOAuthProvider>
    );
}


export default withRoot(App, theme);

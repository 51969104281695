import React, {useContext, useState} from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from "@mui/material/Box"
import SimpleTable from "./SimpleTable";
import {formatDate, boolValue} from "../utils";
import { AjaxForm, AppForm, RFTextField, Typography, required } from "muistarter";
import { Field } from 'react-final-form';
import {linkWithIcon, isValidBase64} from "../utils";
import {SubscriptionContext} from "../contexts";
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Grid from "@mui/material/Grid";
import { capitalize } from "@mui/material/utils"


const licenseURL = process.env.REACT_APP_LICENSE_URL;
const refreshInterval = parseInt(process.env.REACT_APP_LICENSE_LIST_REFRESH_INTERVAL) * 1000;


const columns = [
    {
        id: 'download',
        label: 'Download',
        noObjValue: false,
        minWidth: 100,
        maxWidth: 100,
        format: (value, row, sx) => linkWithIcon(value, row.url, GetAppIcon, sx)
    },
    {
        id: 'machine_key',
        label: 'Machine  Key',
        minWidth: 250,
        maxWidth: 250},
    {
        id: 'generated_on',
        label: 'Generated',
        format: (value) => capitalize(formatDate(value)),
        minWidth: 250,
        maxWidth: 250
    },
    {
        id: 'expires',
        label: 'Expiry Date',
        format: (value) => capitalize(formatDate(value)),
        minWidth: 250,
        maxWidth: 250
    },
    {
        id: 'version',
        label: 'Version'},
    {
        id: 'features',
        label: 'Features',
        minWidth: 250,
        maxWidth: 250
    },
    {
        id: 'evaluation',
        label: 'Evaluation',
        format: boolValue,
        minWidth: 140,
        maxWidth: 140
    }
];


const licenseInstructions = [
    "Install one of the above packages and run 'lilicense -k' from the command line to get your unique machine key.",
    "Enter the machine key and a password of your choice in the license generator form.",
    "Download the resulting license file and install with 'lilicense filename'.",
    "When prompted, enter the same password you previously entered in the form when the license was generated."]


const validate = values => {
  const errors = required(['machine_key', 'password'], values);

  if (!errors.machine_key) {
    const machineKeyError = isValidBase64(values.machine_key);
  if (machineKeyError) {
      errors.machine_key = machineKeyError;
    }
  }
  return errors;
};


const getInstanceUrl = (row) => {
    return `${licenseURL}${row.slug}/`;
}


const Licenses = () => {

    const [ajaxKey, setAjaxKey] = useState(0);

    const refreshTable = () => {
        setAjaxKey(ajaxKey + 1);
    }

    const subscriptionDetails = useContext(SubscriptionContext);
    const subscribed = subscriptionDetails.subscribed;

    return (
        <>
        <SimpleTable tableId="license-list" ajaxKey={ajaxKey} columns={columns} remoteUrl={licenseURL} title="Licenses"
                     description={["Previously Generated Licenses. Click the filename to download:"]}
                     actions={[{name: "Delete", icon: DeleteForeverIcon, method: "DELETE",
                         getUrl: getInstanceUrl, dialogID: "delete-license-dialog",
                         dialogTitle: "Delete license?",
                         dialogText: "Are you sure you want to delete this license?",
                         getSuccessMessage: () => {return "License has been deleted"}
                     }]}
                     runAtInterval={refreshInterval}>
        </SimpleTable>
          <Box id="license-generation-text" sx={{mt: 3}}>
              {subscribed ?
              <>
                  <Grid container direction="row">
                            <Grid item xs={12} md={6}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="You may only generate licenses for yourself or your direct team members in the same company. To generate and install a new license:"
                                                      primaryTypographyProps={{align: "left"}}
                                        />
                                    </ListItem>
                                    {licenseInstructions.map((text, index) => {
                                        return (
                                    <ListItem key={index}>
                                        <ListItemText inset primary={text} primaryTypographyProps={{align: "left",}}/>
                                </ListItem>
                                )
                                })}
                                </List>
                            </Grid>
                      <Grid item xs={12} md={6}>
          <AppForm align="left">
              <Typography variant="h4" gutterBottom marked="center" align="center">
                LICENSE GENERATOR
            </Typography>
            <AjaxForm formID="license-form" url={licenseURL} method="POST" validate={validate}
                  buttonText="Generate License" onSuccess={refreshTable} restartFormOnSuccess
                      successMessage="You can now download your new license."
                  analyticsEventArgs={{category: 'License', action: 'Create'}}>
              <Field
                component={RFTextField}
                fullWidth
                label="Machine Key"
                margin="normal"
                name="machine_key"
                required
                size="large"
                placeholder="Result of 'lilicense -k'"
                autoComplete="off"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                required
                name="password"
                autoComplete="new-password"
                label="Password"
                type="password"
                placeholder="To be re-entered on license install"
                margin="normal"
              />
            </AjaxForm>
          </AppForm>
                      </Grid>
                  </Grid>
              </> :
                  <Typography align="left" component="p">
                      Subscribed users can generate licenses for themselves and team members here.
                  </Typography>}</Box>
        </>
    )
}


Licenses.propTypes = {
}

export default Licenses;
import React from "react";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import {Chip} from "@mui/material";
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import { getIssueType } from "./IssueForm";
import PropTypes from "prop-types";




const openChipStyles = {
    chip: {
        backgroundColor: "success.main",
        color: "primary.contrastText"
        },
    icon: {
        color: "primary.contrastText"
        },
}



const closedChipStyles ={
    chip: {
        backgroundColor: "error.dark",
        color: "primary.contrastText"
    },
    icon: {
        color: "primary.contrastText"
    },
}


const productChipStyles = {
    chip: {
        backgroundColor: "secondary.main",
        color: "primary.contrastText"
    }
}


const noOwnerChipStyles = {
    chip: {
        backgroundColor: "primary.dark",
        color: "primary.contrastText"
        },
    icon: {
        color: "primary.contrastText"
        },
}



const assignedToYouChipStyles = {
    chip: {
        backgroundColor: "success.main",
        color: "primary.contrastText"
    },
    icon: {
        color: "primary.contrastText"
    },
}


const assignedToOtherChipStyles = {
    chip: {
        backgroundColor: "secondary.main",
        color: "primary.contrastText"
    },
    icon: {
        color: "primary.contrastText"
    },
}


export const OpenChip = props => {
    const { issueSlug } = props;
    const chipId = `open-issue${issueSlug? "-" + issueSlug: ''}`
    return (
         <Chip id={chipId} label="Open"
               sx={openChipStyles.chip} icon={<ReportOutlinedIcon fontSize="small" sx={openChipStyles.icon}/>}/>
    )
}


OpenChip.propTypes = {
    issueSlug: PropTypes.string,
}


export const ClosedChip = props => {
    const { issueSlug } = props;
    const chipId = `closed-issue${issueSlug? "-" + issueSlug: ''}`
    return (
         <Chip id={chipId}  label="Closed"
               sx={closedChipStyles.chip} icon={<CheckCircleOutlinedIcon
             fontSize="small" sx={closedChipStyles.icon}/>}/>
    )
}



ClosedChip.propTypes = {
    issueSlug: PropTypes.string,
}


export const ClosedByChip = props => {
    const { closedBy, issueSlug } = props;
    const chipId = `closed-by-issue${issueSlug? "-" + issueSlug: ''}`
    return (
         <Chip id={chipId} label={`Closed By ${closedBy}`}
               sx={closedChipStyles.chip} icon={<CheckCircleOutlinedIcon fontSize="small" sx={closedChipStyles.icon}/>}/>
    )
}


ClosedByChip.propTypes = {
    closedBy: PropTypes.string,
    issueSlug: PropTypes.string,
}


export const ProductChip = props => {
    const { issueSlug } = props;
    const chipId = `product-issue${issueSlug? "-" + issueSlug: ''}`
    return (
         <Chip id={chipId} label={getIssueType(props.product)} sx={productChipStyles.chip}/>
    )
}


ProductChip.propTypes = {
    issueSlug: PropTypes.string,
}



export const NoOwnerChip = props => {
    const { issueSlug } = props;
    const chipId = `issue-owner${issueSlug? "-" + issueSlug: ''}`
    return (
         <Chip id={chipId}
             label="No Owner" sx={noOwnerChipStyles.chip} icon={<SentimentDissatisfiedOutlinedIcon
             fontSize="small" sx={noOwnerChipStyles.icon}/>}/>
    )
}


NoOwnerChip.propTypes = {
    issueSlug: PropTypes.string,
}



export const AssignedToChip = props => {
    const {owner, issueSlug} = props;
    const chipId = `issue-owner${issueSlug? "-" + issueSlug: ''}`
    return (
         <Chip id={chipId} label={`Assigned To ${owner}`}
               sx={owner==="You" ? assignedToYouChipStyles.chip : assignedToOtherChipStyles.chip}
               icon={<FaceOutlinedIcon fontSize="small" sx={assignedToYouChipStyles.icon}/>}/>
    )
}


AssignedToChip.propTypes = {
    owner: PropTypes.string,
    issueSlug: PropTypes.string,
}


import React, {useEffect, useState} from 'react';
import Tab from '@mui/material/Tab';
import AppBar from "@mui/material/AppBar";
import ResponsiveTabMenu from "./ResponsiveTabMenu";
import Tooltip from "@mui/material/Tooltip";
import HexDecoder from "./HexDecoder";
import FileDecoderBinary from "./FileDecoderBinary";
import FileDecoderPCAP from "./FileDecoderPCAP";
import Box from "@mui/material/Box";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import PropTypes from "prop-types";
import { Paper, pageViewAnalytics } from "muistarter"


const tabs = ['hex', 'binary', 'pcap'];


const root = {
    paddingBottom: {'md': 3}
}


const sendTabPageView = (tab) => {
    const tabName = tabs[tab];
    pageViewAnalytics(`/tab/${tabName}/`)
}


const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const a11yProps = (index) => {
  return {
    id: `decoder-tab-${index}`,
    'aria-controls': `decoder-tabpanel-${index}`,
  };
}


const DecoderTabMenu = props => {
    const {preferences, reset, setPreferences, toggleFullScreen, tooltipKey} = props;

    const [showTabsMobile, setShowTabsMobile] = useState(false);
    const [value, setValue] = useState(preferences.defaultDecoder || 0);

    useEffect(() => {
        sendTabPageView(value);
    }, [value])

    const toggleShowTabsMobile = () => {
         setShowTabsMobile(!showTabsMobile);
     }

    const handleChange = (event, newValue) => {
        if (newValue === 3){
            reset();
            return
        }
        if (newValue === 4) {
            toggleFullScreen();
            return
        }else if (newValue === 5){
        }else {
            setValue(newValue);
            setPreferences({defaultDecoder: newValue});
        }
        toggleShowTabsMobile();
    };

    const { fullScreen } = preferences;
    const tabPanelProps = {fullScreen: fullScreen, preferences: preferences,
        setPreferences: setPreferences}

    return (
          <Paper sx={root}>
            <AppBar id="decoder-bar" position="static">
                <ResponsiveTabMenu value={value} onChange={handleChange} aria-label="decoder tabs"
                                   showTabsMobile={showTabsMobile}
                resetIcon={
                        <Tooltip
                            title="Reset Decoder View">
                            <RotateLeftIcon/>
                        </Tooltip>
                    } resetProps={{...a11yProps(3)}}
                fullScreenIcon={
                        <Tooltip key={tooltipKey}
                            title={fullScreen ? "Exit Full Screen Decoder" : "Enter Full Screen Decoder"}>
                            {fullScreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
                        </Tooltip>
                    } fullScreenProps={{...a11yProps(4)}}>
                    <Tab label="Hex Decoder" {...a11yProps(0)} />
                    <Tab label="File Decoder" {...a11yProps(1)} />
                    <Tab label="PCAP Decoder" {...a11yProps(2)} />
                </ResponsiveTabMenu>
            </AppBar>
            <TabPanel value={value} index={0}>
                <HexDecoder {...tabPanelProps} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FileDecoderBinary {...tabPanelProps} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <FileDecoderPCAP {...tabPanelProps} />
            </TabPanel>
            </Paper>
    )
}


DecoderTabMenu.propTypes = {
    preferences: PropTypes.object,
    reset: PropTypes.func,
    setPreferences: PropTypes.func,
    toggleFullScreen: PropTypes.func,
    tooltipKey: PropTypes.number,
}

export default DecoderTabMenu;

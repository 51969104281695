import { createTheme } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';

const rawTheme = createTheme({
  palette: {
    primary: {
      light: '#be1018',
      main: '#28282a',
      dark: '#e62958',
    },
    secondary: {
      light: '#ffffff',
      main: '#172A3A',
      dark: '#0E1C28',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
    background: {
      paper:"#000000",
      default:"#172A3A",
      placeholder: grey[200]
    },
    text: {
      primary: '#ffffff',
      secondary: '#000000',
      link: '#707070'
    },
    action: {
      disabled: '#000000',
      hover: '#F5F5F5',
      selected: '#F5F5F5',
    },
    card: {
      header: '#F6F8FA',
      ownHeader: '#F1F8FF'
    },
    listItem: {
      focus: '#F5F5F5'
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#000000',
        '& h6': {
          color: 'red'
        }
      }
    }
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
});

const fontHeader = {
  color: rawTheme.palette.primary.main,
  fontWeight: rawTheme.typography.fontWeightMedium,
  textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      //default: rawTheme.palette.common.white,
      //placeholder: grey[200],
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#000000',
        '& h6': {
          color: 'red'
        }
      }
    }
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    }
  }
};


export default theme;
import React, {useContext, useState} from 'react';
import { UserContext, Typography } from "muistarter";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ReactMarkdown from "react-markdown";
import {
  List,
  ListItem,
} from "@mui/material";
import CommentForm from "./CommentForm";
import {formatDate, isModified} from "../utils";
import PropTypes from "prop-types";


const commentURL = process.env.REACT_APP_COMMENT_URL


const commentSx = {
  cardHeader: {
    backgroundColor: "card.header",
    color: "primary.main"
  },
  cardHeaderOwn: {
    backgroundColor: "card.ownHeader",
    color: "primary.main"
  },
  cardContent: {
    backgroundColor: "secondary.light",
    color: "text.primary"
  },
  cards: {
    width:"100%",
    color: "text.primary"
  },
};


const commentListSx = {
  root: {
    width: "100%",
  },
  fonts: {
    fontWeight: "bold"
  },
  primaryText: {
    display: "inline"
  }
};


const Comment = props => {

  const [editMode, setEditMode] = useState(false);

  const {comment, reload, ...commentFormProps} = props;

  const toggleEditMode = () => {
    setEditMode(!editMode);
  }

  const reloadComment = () => {
    toggleEditMode()
    reload();
    return true;
  }

  const commentUrl = `${commentURL}${comment.slug}/`
  const initialValues = {text: comment.text}

  const user = useContext(UserContext).user;

  return (
      <ListItem alignItems="flex-start">
          {editMode ? <CommentForm initialValues={initialValues} url={commentUrl} method="PUT"
                                              buttonText='Submit Changes' onSuccess={reloadComment}
                                              label="Edit Comment" markdownIdSuffix={`-${comment.slug}`}
                                              id={`edit-comment-${comment.slug}-field`} {...commentFormProps}/>:
              <Card id={`comment-${comment.slug}`} sx={commentSx.cards}>
                 <CardHeader
                    id={`comment-dates-${comment.slug}`}
                    sx={user && comment.user && comment.user.id === user.id ? commentSx.cardHeaderOwn : commentSx.cardHeader}
                    action={user && comment.user && comment.user.id === user.id &&
                      <IconButton id={`edit-comment-${comment.slug}`} edge="end" aria-label="Edit comment"
                                  onClick={toggleEditMode} size="small">
                        <EditIcon sx={commentSx.cardHeader} />
                      </IconButton>
                    }
                title={`${user && comment.user && user.id === comment.user.id ? "You" : comment.user ? comment.user.name: "Unknown user"} commented ${formatDate(comment.created_on, true)}`}
                titleTypographyProps={{variant: "body2"}}
                subheader={isModified(comment) && `Last modified ${formatDate(comment.modified_on, true)}`}
                subheaderTypographyProps={{variant: "body2"}}
                 />
                <CardContent id={`comment-text-${comment.slug}`} sx={commentSx.cardContent}>
                  <Typography variant="body2" component="span" color="primary">
                    <ReactMarkdown>{comment.text}</ReactMarkdown>
                    </Typography>
                </CardContent>
              </Card>
          }
          </ListItem>
  )
}


Comment.propTypes = {
  comment: PropTypes.object,
  reload: PropTypes.func,
}


const CommentList= props => {

    const { comments, ...commentProps } = props;

    return (
      <List id="comment-list" sx={commentListSx.root}>
        {comments.map(comment => {
          return (
            <Comment key={comment.slug} comment={comment} {...commentProps}/>
          )}
        )}
      </List>
    );
}


CommentList.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
}


export default CommentList;

import React from 'react';
import { RFTextField} from "muistarter";
import { MenuItem } from '@mui/material';
import PropTypes from "prop-types";


const menuItemSX = [
    { color: "text.secondary"},
  ]



const SelectField = props => {
    const {label, options, margin="normal", ...fieldProps} = props;


    return (
       <RFTextField
         id={`select-${fieldProps.input.name}`}
         fullWidth
         select
         label={label}
         margin={margin}
         SelectProps={{MenuProps: {PaperProps: {sx: {backgroundColor: "secondary.light"}}}}}
         {...fieldProps}
          >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={menuItemSX}>
                    {option.label}
                </MenuItem>
              ))}
       </RFTextField>
    )
}


SelectField.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object)
}


export default SelectField;


import IssueForm from './IssueForm'
import {useParams} from "react-router-dom";


const issueURL = process.env.REACT_APP_ISSUE_URL


const IssueModify = props => {

    const { slug } = useParams();

    const getRemoteIssueUrl = () => {
        return `${issueURL}${slug}/`
    }

    const getRemoteIssueUrlChangeable = () => {
        return `${getRemoteIssueUrl()}?changeable`
    }

  const url = getRemoteIssueUrl();
  const initialValuesUrl = getRemoteIssueUrlChangeable();
  const redirect = {
      pathname: '/issues/view/',
  }

  return (
          <IssueForm url={url} method="PUT" heading="Modify Issue" buttonText='Submit Changes'
                     redirectTo={redirect} loadInitialValuesFromURL={initialValuesUrl}
                     successMessage="Issue was successfully updated." analyticsEventAction="Modify Issue"
              analyticsInitialValuesAction="Get Issue for modify form">
          </IssueForm>
  );
}


IssueModify.propTypes = {
}

export default IssueModify;
import React, {useRef} from "react";
import FileDecoder from "./FileDecoder";
import {Field} from "react-final-form";
import {Switches} from "mui-rff";
import { RFTextField } from "muistarter";
import { removeNullValuesFromObject } from "../utils"
import PropTypes from "prop-types";

const filesLimit = parseInt(process.env.REACT_APP_MAX_SIMULTANEOUS_PCAP_FILES || 100);
const refreshOpenPcapRequestsInterval = parseInt(process.env.REACT_APP_DECODE_REQUEST_REFRESH_INTERVAL_ON_OPEN_PCAP_REQUEST) * 1000;
const maxFileSize = parseInt(process.env.REACT_APP_PCAP_MAX_SIZE_MB) * 1024 * 1024;


const FileDecoderPCAP = props => {

    const {preferences} = props;
    const initialValues = preferences.pcapParams;

    const ftpSwitchData = useRef([
        {label: 'Uses FTP'}
    ]);

    const tpktSwitchData = useRef([
        {label: 'Uses TPKT'}
    ]);

    const storeAdditionalParams = (preferences, data) => {
        let  params = {
            display_filter: data.get('display_filter'),
            pdu_filter: data.get('pdu_filter'),
            packet_count: data.get('packet_count'),
            ftp: data.get('ftp'),
            tpkt: data.get('tpkt')
        }
        params = removeNullValuesFromObject(params);
        if (params.packet_count) params.packet_count = parseInt(params.packet_count);
        if (params.ftp === "true") params.ftp = true;
        else if (params.ftp === "false") params.ftp = false;
        if (params.tpkt === "true") params.tpkt = true;
        else if (params.tpkt === "false") params.tpkt = false;

        return (Object.keys(params).length > 0) ? {pcapParams: params} : {};
    }

    return (
        <>
        <FileDecoder request_type="pcap_files" maxFileSize={maxFileSize} formID="pcap-decoder-form"
                openRequestsInterval={refreshOpenPcapRequestsInterval} storeAdditionalParams={storeAdditionalParams}
                initialValues={initialValues} gaAction="Get Binary Requests" filesLimit={filesLimit}
                analyticsRemoveEventArgs={{category: 'File Decoder', action: 'Remove PCAP Request'}}
                analyticsEventArgs={{category: 'File Decoder', action: 'Create PCAP Request'}}
                analyticsListEventArgs={{category: 'File Decoder', action: 'List PCAP Requests'}} {...props}>
               <Field
                      component={RFTextField}
                      fullWidth
                      label="Display Filter (e.g. tcp.port==4000)"
                      name="display_filter"
                      margin="normal"
               />
               <Field
                      component={RFTextField}
                      fullWidth
                      label="PDU Contents Filter (search for strings, e.g. liid)"
                      name="pdu_filter"
                      margin="normal"
               />
               <Field
                      component={RFTextField}
                      type="number"
                      fullWidth
                      label="Packet Count"
                      name="packet_count"
                      margin="normal"
               />
               <Switches
                      color="primary"
                      name="ftp"
                      data={ftpSwitchData.current}

               />
               <Switches
                      color="primary"
                      name="tpkt"
                      data={tpktSwitchData.current}
               />
        </FileDecoder>
        </>
    )
}

FileDecoderPCAP.propTypes = {
    preferences: PropTypes.object,
}

export default FileDecoderPCAP;


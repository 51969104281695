import React from "react";
import moment from "moment";
import {capitalize} from "@mui/material/utils";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon"


export const formatDate = (date, finishSentence=false) => {
    let m = moment(date);
    if ((new Date()) - m < 86400000) return m.fromNow();
    let timestampStr = m.format('MMMM Do YYYY, h:mm a');
    if (finishSentence) return "on " + timestampStr;
    return timestampStr;
}

export const isModified = obj => {
    return new Date(obj.modified_on) - new Date(obj.created_on) > 60000
}


export const convertEpochTime = epoch => {
    const d = new Date(0);
    d.setUTCMilliseconds(epoch);
    return d;
}

const reHex = new RegExp(/^[0-9a-fA-F]+$/);
const reBase64 = new RegExp(/^[A-Za-z0-9+/=]+$/);

export const isValidHex = str => {
    if (!reHex.test(str)) return 'This field contains invalid hex characters'
    else return null
}

export const hasEvenDigits = str => {
    if (str.length % 2) return 'Hex values must have an even number of digits'
    else return null
}


export const isValidBase64 = str => {
    if (!reBase64.test(str)) return 'This field contains invalid base64 characters and so is not a valid machine key.';
    else return null
}


export const checkOutputFormatNeedsStandard = values => {
    if (values.standard === 'AUTO_DETECT_HI' && values.output_format !== 'yaml') {
        return `You must select a specific standard for output format ${values.output_format}`
    }
}


export const removeNullValuesFromObject = obj => {
    return Object.entries(obj).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})
}


export const any = (arr, func) => {
    if (!arr) return false;
    let is = false;
    for (let i = 0; i < arr.length; i++){
        let obj = arr[i];
        if (func(obj)){
            is = true;
            break;
        }
    }
    return is;
}


export const paddedDate = date => {
    return date.toString().padStart(2, '0')
}


export const dateToString = date => {
     return `${date.getFullYear()}${paddedDate(date.getMonth() + 1)}${paddedDate(date.getDate() + 1)}${paddedDate(date.getHours())}${paddedDate(date.getMinutes())}${paddedDate(date.getSeconds())}`;
}


export const nowToString = () => {
    const date = new Date();
    return dateToString(date);
}


export const convert = (value, converters) => {
    return capitalize(converters[value] || value);
}


export const boolValue = (value) => {
    return value ? "Yes": "No";
}


export const linkify = (value, url) => {
    return <Link href={url} underline="none">{value}</Link>
}

export const iconify = (value, icon) => {
    return <Grid container direction="row" alignItems="center">{value}<Icon component={icon}/></Grid>
}


export const linkWithIcon = (value, url, icon, sx) => {
    return linkify(iconify(value, icon, sx), url)
}

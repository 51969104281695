import React, {useState, useEffect, useContext} from "react";
import {SubscriptionContext} from "../contexts";
import { useLocation, useNavigate } from "react-router-dom"
import { Announcement, UserContext } from "muistarter";


const getSubscriptionMessage = (user, subscribed, checkoutSessionID) => {
    if (subscribed) {
        return "You have subscribed successfully. Thank you for subscribing with us and we hope you find our service useful. If you have any problems feel free to open an issue and we will look into it. Click on billing to retrieve a copy of your receipt. Click Downloads to generate licenses for the decoding tools. Good luck!"
    } else if (!user) {
         return "Thank you for subscribing with us. Please login to check if your subscription is active."
    } else{
        return "Thank you for subscribing with us. It may take some time for your subscription to complete. Please refresh the page after a few minutes. If you have any problems feel free to open an issue and we will look into it. Click on billing to retrieve a copy of your receipt. Click Downloads to generate licenses for the decoding tools. Good luck!"
    }
}


const SubscriptionAnnouncement = () => {

    const [message, setMessage] = useState(null);
    const [toastOptions, setToastOptions] = useState(null);
    const [showAnnouncement, setShowAnnouncement] = useState(false);

    //const subscriptionDetails = useContext(SubscriptionContext);
    const user = useContext(UserContext).user;
    const subscriptionDetails = useContext(SubscriptionContext);
    const subscribed = subscriptionDetails.subscribed;
    const subscriptionChecked = subscriptionDetails.checked;

    const location = useLocation();
    const pathname = location.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        // Maybe can use useNavigate hook here?
        if (subscriptionChecked) {
            let msg
            let redirectTo = null;
            let options = {}

            const urlParams = new URLSearchParams(window.location.search);
            const checkoutSessionID = urlParams.get('session_id');
            if (checkoutSessionID) {
                urlParams.delete('session_id')
                msg = getSubscriptionMessage(user, subscribed, checkoutSessionID)
                options.type = subscribed ? "success": "info"
                redirectTo = pathname;
            }
            setToastOptions(options);
            setMessage(msg);
            setShowAnnouncement(true);
            if (redirectTo) navigate(redirectTo);
        }
    }, [user, subscribed, subscriptionChecked])       // Will this work with [subscriptionDetails] here?

    return (
        <>
        {showAnnouncement && <Announcement msg={message} toastOptions={toastOptions}/>}
        </>
    )

}


SubscriptionAnnouncement.propTypes = {
}


export default SubscriptionAnnouncement;


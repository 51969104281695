import React, {useRef} from 'react';
import { Field } from 'react-final-form'
import MarkdownPreview from './Markdown'
import SelectField from "./SelectField";
import {Switches} from 'mui-rff'
import { AjaxForm, AppForm, RFTextField, Typography, required } from "muistarter";
import PropTypes from "prop-types";


const issueURL = process.env.REACT_APP_ISSUE_URL


const issueTypes = [
    {value: 'website', label: 'Website'},
    {value: 'online', label: 'Online Decoder'},
    {value: 'download', label: 'Downloaded Product'},
    {value: 'billing', label: 'Billing Issue'},
    {value: 'other', label: 'Other'}
]


export const getIssueType = (issue) => {
    const length = issueTypes.length;
    for (let i = 0; i < length; i++){
        let issueType = issueTypes[i];
        if (issueType.value === issue){
            return issueType.label
        }
    }
}


const validate = (values) => {
  return required(['title', 'product',  'text'], values);
};


const IssueForm = props => {

  const switchData = useRef({label: 'Email notifications'})

  let { redirectTo, buttonText="Submit New Issue", method= 'POST', url=issueURL, heading="Create An Issue",
      successMessage ="A new issue has been created. We will get back to you soon.",
      analyticsEventAction='Create an issue', ...ajaxFormProps } = props

  const analyticsEventArgs={category: 'Issues', action: analyticsEventAction};

  redirectTo = redirectTo || {
          pathname: "/issues/view/"
  }

  const initialValues = {email_notifications: true, product: 'website'}

  return (
    <AppForm>
        <Typography variant="h3" gutterBottom marked="center" align="center">
            {heading}
        </Typography>
      <AjaxForm url={url} method={method} redirectTo={redirectTo} validate={validate}
                buttonText={buttonText} initialValues={initialValues}
                successMessage={successMessage} addResponseToRedirectState
                addFieldToRedirectPathname="slug" analyticsEventArgs={analyticsEventArgs} {...ajaxFormProps}>
              <Field
                  autoFocus
                  component={RFTextField}
                  fullWidth
                  label="Title"
                  name="title"
                  required
              />
              <Field
                  component={SelectField}
                  label="Category"
                  name="product"
                  options={issueTypes}
                  required
                  >
              </Field>
              <Switches
                  color="primary"
                  name="email_notifications"
                  required
                  data={switchData.current}
              />
              <Field
                  component={RFTextField}
                  fullWidth
                  label="Details"
                  name="text"
                  multiline
                  rows={10}
                  size="xlarge"
                  variant="outlined"
                  required
              />
              <MarkdownPreview fieldName="Details" field="text"/>
      </AjaxForm>
    </AppForm>
  );
}


IssueForm.propTypes = {
    redirectTo: PropTypes.object,
    buttonText: PropTypes.string,
    method: PropTypes.string,
    url: PropTypes.string,
    heading: PropTypes.string,
    successMessage: PropTypes.string,
    analyticsEventAction: PropTypes.string
}

export default IssueForm;
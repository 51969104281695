import React, {useContext} from 'react';
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import {Link as RouterLink} from 'react-router-dom';
import {Typography, UserContext} from "muistarter"
import ReactMarkdown from "react-markdown";
import {SubscriptionContext} from "../contexts";
import PropTypes from "prop-types";


const betaTestFormLink = process.env.REACT_APP_BETA_TEST_FORM_URL;
const docsLink = process.env.REACT_APP_DOCS_LINK;


const root = {
  marginLeft: 1,
  marginRight: 1,
}


const linkSx = {
    color: "text.link"
}


const MarkdownRouterLink = props => {
    const {href, children} = props;

  return (
    href.match(/^(https?:)?\/\//)
        ? <Link sx={linkSx} href={href} target="blank">{children}</Link>
      : <Link sx={linkSx} component={RouterLink} to={href}>{children}</Link>
  );
}

MarkdownRouterLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.node
}


const offlineDecodersText = `

Monitoring Centre Simulator:

- We now have a monitoring centre simulator, the liserver script. 

- Starts a tcp or sftp server for receiving HI2 records like a Monitoring Centre, and saves the records in a logical directory structure.

- Does not yet playback intercepted call contents, but can decode them and present the data in an easy to read format.

- Check the [documentation](${docsLink} "Documentation") for more information. A premium subscription is required. Or click request free access to get an evaluation license.

Other offline tools:
- File Decoder: Decode from Raw ASN.1 files as they are delivered over the HI Interfaces (HI1, HI2, HI3, HI4)

- PCAP Decoder: Find ASN.1 records inside a packet capture taken for a HI interface connection.

- See the [documentation](${docsLink} "Documentation") for more information.
`


const onlineDecodersText = `

Online decoders:

- Hex: Decode from Raw Hex. One way to get raw hex is to right click a packet in wireshark and click "copy > as a Hex Stream"

- File Decoder: Decode from Raw ASN.1 files as they are delivered over the HI Interfaces (HI1, HI2, HI3, HI4)

- PCAP Decoder: Find ASN.1 records inside a packet capture taken for a HI interface connection. 
  Use display filters and pdu filters where possible to speed up decoding.
  For more information on display filters see [here](https://tshark.dev/analyze/packet_hunting/packet_hunting/ "More information on display filters").
  PDU filters will filter packets to those whose contents contain the given string. Try searching for a lawful interception identifier.
`


const standardsSupportedLine = process.env.REACT_APP_STANDARDS_LINE + " (with backward compatibilty to the earliest standards)"

const markdownTextNotLoggedIn =

` Downloadable LI standards ASN.1 decoder and Monitoring Centre Simulator now available!

This site contains a group of essential tools useful for anyone working in the field of Lawful Interception, experts and newbies alike:

- Decode HI records in all of the most common standards for Lawful Interception (ETSIv3, ETSI232, 3GPP) for a range of technologies (IPMM, EPS, CS and more)

- ${standardsSupportedLine}

- Get full decoded record, or various summary formats

- Decode from raw hex records for free

- Decode from hi records stored in files with a subscription (one free request available, [sign-up](sign-up "Sign Up") to try it)

- Decode from packet captures with a subscription (one free request available, [sign-up](sign-up "Sign Up") to try it)

- Questions? Problems? [sign-up](sign-up "Sign Up") and click support to open an issue in our issue tracker

- Offline decoder and MC simulator tools now available

- Note that hex data and files are uploaded to our servers and stored in order to decode them and maybe stored for longer. Do not upload live subscriber data. Use our offline decoders for that.

- Click [here](${betaTestFormLink} "Request Free Access") to request free temporary full access to our online decoders, and an evaluation license for our offline tools.

${offlineDecodersText}

${onlineDecodersText}
`


const markdownTextLoggedIn =

` Beta test now open. Click [here](${betaTestFormLink} "Apply for beta test") to join and get full access. 

  This is a tool which should be useful for anyone working in the field of Lawful Interception, experts and newbies alike:

- Decode HI records in all of the most common standards for Lawful Interception (ETSIv3, ETSI232, 3GPP) for a range of technologies (IPMM, EPS, CS and more)

- ${standardsSupportedLine}

- Get full decoded record, or various summary formats

- Decode from raw hex records for free

- Decode from hi records stored in files with a subscription. One free request available. If it is useful for you, click [here](subscriptions "Subscribe") to subscribe for unlimited decoding

- Decode from packet captures with a subscription. One free request available. If it is useful for you, click [here](subscriptions "Subscribe") to subscribe for unlimited decoding.

- Questions? Problems? Click [here](issues "Issue Tracker") to open an issue in our issue tracker

- Offline decoder and MC simulator tools coming soon. If you have not done so, click [here](profile "User Profile") to subscribe to our mailing list for the latest updates

- Note that hex data and files are uploaded to our servers and stored in order to decode them and maybe stored for longer. Do not upload live subscriber data. Wait for our offline decoders to be released for that


${onlineDecodersText}
`


const markdownTextSubscribed =

`Thank you for being a subscriber.

This is a tool which should be useful for anyone working in the field of Lawful Interception, experts and newbies alike:

- Decode HI records in all of the most common standards for Lawful Interception (ETSIv3, ETSI232, 3GPP) for a range of technologies (IPMM, EPS, 5G Core, CS and more)

- ${standardsSupportedLine}

- Get full decoded record, or various summary formats

- Decode from raw hex records

- Decode from hi records stored in files. Unlimited decoding with your subscription.

- Decode from packet captures with a subscription. Unlimited decoding with your subscription.

- Questions? Problems? Click [here](issues "Issue Tracker") to open an issue in our issue tracker

- Offline decoder and MC simulator tools coming soon. If you have not done so, click [here](profile "User Profile") to subscribe to our mailing list for the latest updates

- Note that hex data and files are uploaded to our servers and stored in order to decode them and maybe stored for longer. Do not upload live subscriber data. Wait for our offline decoders to be released for that


${onlineDecodersText}
`


const HomeText = () => {
    const user = useContext(UserContext).user;
    const subscribed = useContext(SubscriptionContext).subscribed;

    return (
        <Box id="home-text" sx={root}>
            <Typography component="h1" variant="h2" align="center" gutterBottom>
                ONLINE LI DECODER
            </Typography>
            <Typography variant="body1" align="left" component="span">
                <ReactMarkdown components={{a: MarkdownRouterLink}}>
                    {subscribed ? markdownTextSubscribed : (user ? markdownTextLoggedIn : markdownTextNotLoggedIn)}
                </ReactMarkdown>
            </Typography>
        </Box>
    )
}


HomeText.propTypes = {
}



export default HomeText;
import React, {useState} from 'react';
import { AjaxRequest, Paper, Typography } from "muistarter";
import Icon from "@mui/material/Icon"
import IconButton from "@mui/material/IconButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import AjaxConfirmationDialog from "./AjaxConfirmationDialog";
import PropTypes from "prop-types";


const tableHeader = {
    backgroundColor: "secondary.dark",
}


const tableCell = {
    color: 'text.secondary'
}



const SimpleTable = props => {
    const { tableId, title, description, columns, actions, ajaxKey, remoteUrl, children, ...ajaxRequestProps } = props;

    const [results, setResults] = useState([]);
    const [actionDialogOpen, setActionDialogOpen] = useState(false);
    const [action, setAction] = useState(null);
    const [ajaxKeyState, setAjaxKeyState] = useState(0);
    const [row, setRow] = useState(null);

    const handleResult = data => {
        setResults(data.results || []);
    };

    const onActionComplete = () => {
        setAction(null);
        setRow(null);
        setActionDialogOpen(false);
        setAjaxKeyState(ajaxKeyState + ajaxKey + 1);
    }

    const onActionCancel = () =>{
        setAction(null);
        setRow(null);
        setActionDialogOpen(false);
    }

    const onAction = (action, row, event) => {
        setAction(action);
        setRow(row);
        setActionDialogOpen(true);
    }

  return (
      <div id={tableId}>
           <Typography component="h1" variant="h2" align="left" gutterBottom>
               {title}
           </Typography>
          {children}
          {results && results.length > 0 && <List>
              {description.map((text, index) => { return (<ListItem key={index}>
                   <ListItemText primary={text} primaryTypographyProps={{align: "left"}}/>
              </ListItem>  )})}
          </List>}
              <AjaxRequest key={ajaxKey + ajaxKeyState} url={remoteUrl} method="GET" onSuccess={handleResult} showBackdrop
                analyticsEventArgs={{category: title, action: `List ${title}`}} {...ajaxRequestProps}/>
              <AjaxConfirmationDialog open={actionDialogOpen}
                                onSuccess={onActionComplete}
                                onCancel={onActionCancel}
                                method={action && action.method}
                                url={action && action.getUrl(row) }
                                title={action && action.dialogID}
                                      dialogTitle={action && action.dialogTitle}
                                dialogText={action && action.dialogText}
                                getSuccessMessage={action && action.getSuccessMessage}
                                analyticsEventArgs={{
                                    category: title,
                                    action: action && action.name
                                }}/>
    {results && (results.length > 0) && <Paper>
    <TableContainer>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              sx={{...tableHeader, minWidth: column.minWidth, maxWidth: column.maxWidth}}
              variant="head"
            >
              {column.label}
            </TableCell>
          ))}
              {actions && actions.map((action) => {
                return (

                  <TableCell
                      key={action.name}
                      sx={{...tableHeader, minWidth: action.minWidth, maxWidth: action.maxWidth}}
                      >
                      {action.name}
                  </TableCell>

                );
              })}
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map((row) => {
          return (
            <TableRow hover tabIndex={-1} key={row.slug}>

              {columns.map((column) => {
                const value = column.noObjValue ? "" : row[column.id];
                return (

                  <TableCell key={column.id} align={column.align} sx={tableCell}>
                      <Typography component="span" variant="body1">{column.format ? column.format(value, row): value}</Typography>
                  </TableCell>

                );
              })}

                {actions && actions.map((action) => {
                return (

                  <TableCell key={action.name} align="center">
                       <IconButton id={`${action.name}-${row.slug}`} onClick={(event) => onAction(action, row, event)}>
                           <Icon component={action.icon}/>
                       </IconButton>
                  </TableCell>

                );
              })}

            </TableRow>
          );
        })}
      </TableBody>
    </Table>
    </TableContainer>
    </Paper>}
    </div>
  );
}


SimpleTable.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    ajaxKey: PropTypes.number,
    children: PropTypes.node,
    columns: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.arrayOf(PropTypes.string),
    remoteUrl: PropTypes.string,
    tableID: PropTypes.string,
    title: PropTypes.string,
}



export default SimpleTable;
import React from 'react';
import MarkdownPreview from "./Markdown";
import { AjaxForm, RFTextField, required } from "muistarter";
import { Field } from "react-final-form"
import PropTypes from "prop-types";

const issueURL = process.env.REACT_APP_ISSUE_URL


const validate = (values) => {
    return required(['text'], values);
};


const CommentForm = props => {

  let { id='add-comment-field', label="Add new comment", method='POST',
      url, issue, initialValues, buttonText='Add Comment', markdownIdSuffix,
      analyticsEventAction, classes, ...ajaxFormProps } = props

  url = url || `${issueURL}${issue.slug}/add_comment/`
  const analyticsEventArgs = {category: 'Comments', action: analyticsEventAction || 'Creating a comment'};

  return (
      <>
          <AjaxForm url={url} method={method} validate={validate} showBackdrop
                    initialValues={initialValues} buttonText={buttonText}
                    analyticsEventArgs={analyticsEventArgs} {...ajaxFormProps}>
                  <Field
                      component={RFTextField}
                      fullWidth
                      id={id}
                      label={label}
                      name="text"
                      multiline
                      rows={5}
                      variant="outlined"
                      size="xlarge"
                  />
              <MarkdownPreview fieldName="Comment" field="text" idSuffix={markdownIdSuffix}/>
          </AjaxForm>
      </>
  );
}


CommentForm.propTypes = {
    analyticsEventAction: PropTypes.string,
    buttonText: PropTypes.string,
    id: PropTypes.string,
    initialValues: PropTypes.object,
    issue: PropTypes.object,
    label: PropTypes.string,
    markdownIdSuffix: PropTypes.string,
    method: PropTypes.string,
    url: PropTypes.string
}

export default CommentForm;
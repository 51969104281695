import React, {useState, useRef} from 'react';
import { AjaxForm, RFTextField, required } from "muistarter";
import {Field} from "react-final-form";
import Grid from '@mui/material/Grid';
import { isValidHex, hasEvenDigits, checkOutputFormatNeedsStandard } from "../utils";
import SelectField from "./SelectField";
import PropTypes from "prop-types";


const hexDecodeUrl = process.env.REACT_APP_HEX_DECODE_URL;


export const defaultHex = {
    AUTO_DETECT_HI: '307ba13b80070400020205010e81083132333435363738a30ca00680044f50455282025553840100851232303139313230383231323534392e363931880102a23ca33aa13880070400020200010681083132333435363738a208a10680044f504552a319a017801232303139313230383231323534392e363931810100'
}


export const standards = [
    {value: 'AUTO_DETECT_HI', label: 'Auto Detect'},
    {value: 'ETSI232', label: 'ETSI232'},
    {value: '3GPP_HI1', label: '3GPP HI1'},
    {value: '3GPP_HI2', label: '3GPP HI2'},
    {value: '3GPP_HI3', label: '3GPP HI3'},
    {value: '3GPPCS_HI2', label: '3GPP CS HI2'},
    {value: '3GPPEPS_HI2', label: '3GPP EPS HI2'},
    {value: '3GPPEPS_HI3', label: '3GPP EPS HI3'},
    {value: '3GPPVOIP_HI3', label: '3GPP VOIP HI3'},
    {value: '3GPPIMSCONF_HI2', label:'3GPP IMS CONF HI2'},
    {value: '3GPPIMSCONF_HI3', label:'3GPP IMS CONF HI3'},
    {value: 'ETSIV3_HI1', label: 'ETSI V3 HI1'},
    {value: 'ETSIV3_HI2', label: 'ETSI V3 HI2'},
    {value: '3GPP', label: '3GPP Mixed'},
]


export const output_formats = [
    {value: 'yaml', label: 'Default'},
    {value: 'attrs', label: 'As Attributes'},
    {value: 'rows', label: 'As Rows'},
]


const validate = (values) => {
  const errors = required(['binary', 'output_format', 'standard'], values);
  if (!errors.binary) {
    let binaryError = isValidHex(values.binary);
    if (! binaryError ) binaryError = hasEvenDigits(values.binary)
    if (binaryError) {
      errors.binary = binaryError;
    }
  }
  if (! errors.standard){
    const standardError = checkOutputFormatNeedsStandard(values);
    if (standardError) {
        errors.standard = standardError;
    }
  }
  return errors;
};


const HexDecoder = props => {

    const binaryValueChanged = useRef(false);

    const { fullScreen, preferences, setPreferences } = props;

     const [result, setResult] = useState('');

     const initialValues = {
        standard: preferences.standard || 'AUTO_DETECT_HI',
        output_format: preferences.output_format || 'yaml'
     }
     initialValues.binary = defaultHex[initialValues.standard] || '';

    const onSuccess = (values, form, request) => {
        setResult(values.result);
        setPreferences({
            standard: request.data.standard,
            output_format: request.data.output_format
        })
    }

    const onFormChange = (values, form) => {
        if (!binaryValueChanged.current) {
            binaryValueChanged.current = (!Object.values(defaultHex).includes(values.binary))
            if (!binaryValueChanged.current) {
                form.change('binary', defaultHex[values.standard] || '');
            }
        }
    }

      return (
              <Grid container>
                  <Grid item xs={12} md={fullScreen ? 6: 12}>
                <AjaxForm url={hexDecodeUrl} method="POST" validate={validate}
                      formID="hex-decoder-form" onSuccess={onSuccess} buttonText="Decode" noTopPadding
                      initialValues={initialValues} onValuesChange={onFormChange}
                      analyticsEventArgs={{category: 'Hex Decoder', action: 'Decode Hex'}}>
                      <Field
                          component={RFTextField}
                          multiline
                          fullWidth
                          rows={4}
                          label="Hex"
                          name="binary"
                          required
                      />
                      <Field
                          component={SelectField}
                          label="Standard"
                          name="standard"
                          options={standards}
                          required
                          >
                      </Field>
                      <Field
                          component={SelectField}
                          label="Output Format"
                          name="output_format"
                          options={output_formats}
                          required
                          >
                      </Field>
              </AjaxForm></Grid>
                  <Grid item xs={12} md={fullScreen ? 6: 12}>
                   <RFTextField
                       id="decode-result"
                       color="primary"
                       fullWidth
                       multiline
                       size="xlarge"
                       input={{name: 'result', value: result}}
                       meta={{error: '', touched: false}}
                       //InputProps={{disableUnderline: true}}
                   />
                   </Grid>
              </Grid>
      );
}


HexDecoder.propTypes = {
    fullScreen: PropTypes.bool,
    preferences: PropTypes.object,
    setPreferences: PropTypes.func
}


export default HexDecoder

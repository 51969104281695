import React, {useContext, useRef, useState} from "react";
import { UserContext, AjaxRequest, toast } from "muistarter";
import { convertEpochTime } from "./utils"
import PropTypes from "prop-types";


export const SubscriptionContext = React.createContext({subscribed: null, updater: null, refreshSubscription: null,
    checked: false, checkedForCurrentUser: false, limit: null, remaining: null});
SubscriptionContext.displayName = "SubscriptionContext"


const warnSubscriptionExpiringOn = parseInt(process.env.REACT_APP_WARN_SUBSCRIPTION_EXPIRING_ON_HOURS) * 60 * 60 * 1000
const subscriptionUrl = process.env.REACT_APP_SUBSCRIPTION_URL;
const subscriptionWarning = "Your subscription expires soon. If you want to renew, please click Billing to go to the subscription management portal.";


export const SetSubscriptionContext = props => {

    const userDetails = useContext(UserContext);
    const user = userDetails.user;
    const userChecked = userDetails.userChecked;

    const { children } = props;

    const subscriptionOverrides = useRef({});

    const [ajaxKey, setAjaxKey] = useState(0);
    const [subscriptionDetails, setSubscriptionDetails] = useState({subscribed: false, checked: false,
        checkedForCurrentUser: false, limit: null, remaining: null});

    const updateSubscription = (subscribed, limit, remaining) => {
        subscriptionOverrides.current = {};
        setSubscriptionDetails({...subscriptionDetails, subscribed: subscribed, checked: true, checkedForCurrentUser: true,
            limit: limit, remaining: remaining});
    }

    const refreshSubscription = () => {
        setAjaxKey(ajaxKey + 1);
    }


    const updateSubscriptionFromResponse = (response) => {
        updateSubscription(Boolean(response.sub_id), response.limit, response.remaining);
        if (response.cancel_at){
            const cancelled_at = convertEpochTime(response.cancel_at);
            const expiresIn = cancelled_at - new Date();
            if ( expiresIn <= warnSubscriptionExpiringOn){
                toast(subscriptionWarning, {type: 'warning'});
            }
        }
    }

    if (userChecked && !user) {
        subscriptionOverrides.current ={
            subscribed: false,
            checkedForCurrentUser: false,
            checked: true,
        ...subscriptionOverrides.current
        };
    }else {
        subscriptionOverrides.current = {}
    }

    const currentSubscriptionDetails = {...subscriptionDetails, ...subscriptionOverrides.current};

    const contextValue = {
        ...currentSubscriptionDetails,
        updater: updateSubscription,
        refreshSubscription: refreshSubscription
    }

    return (
        <>
            {user && <AjaxRequest key={ajaxKey} url={subscriptionUrl} method="GET"
                     onSuccess={updateSubscriptionFromResponse} showBackdrop
            analyticsEventArgs={{category: 'User', action: 'Check Subscription Status', nonInteraction: true}}/>}
        <SubscriptionContext.Provider value={contextValue}>
            {children}
        </SubscriptionContext.Provider>
        </>
    )
}


SubscriptionContext.PropTypes = {
    children: PropTypes.node
}

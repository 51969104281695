import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {FormSpy} from "react-final-form";
import ReactMarkdown from "react-markdown";
import {OutboundLink} from "react-ga";
import PropTypes from "prop-types";


const MarkdownPreview = props => {

  const {field, fieldName, idSuffix = ''} = props;

  const [showMarkdown, setShowMarkdown] = useState(false);

  const onLinkClick = event => {
        event.preventDefault()
        setShowMarkdown(!showMarkdown);
  }

  return (
        <>
            <Typography variant="body2" align="center">
                      {`Markdown syntax is supported in the ${fieldName} field. For an explanation of Markdown, click `}
                      <OutboundLink eventLabel="Clicked Github Markdown Guide" target="_blank" to="https://guides.github.com/features/mastering-markdown/">here</OutboundLink>.
            </Typography>
            <Typography variant="body2" align="center">
                   <Link id={`markdown-preview-toggle${idSuffix}`} href="#" underline="always" onClick={onLinkClick}>
                         {showMarkdown ? "Disable the Markdown preview" : `Preview the ${fieldName} text in Markdown`}
                   </Link>
            </Typography>
            {showMarkdown && <FormSpy subscription={{ values: true }}>
                  {({ values }) => <span id={`markdown-preview${idSuffix}`}>
                      <ReactMarkdown>{values[field]}</ReactMarkdown></span>}
		    </FormSpy>}
		</>
    )
}


MarkdownPreview.propTypes = {
    field: PropTypes.string,
    fieldName: PropTypes.string,
    idSuffix: PropTypes.string,
}


export default MarkdownPreview;
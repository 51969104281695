import React from 'react';
import {AjaxRequest, sendAnalyticsOutboundLink} from "muistarter";

const portalUrl = process.env.REACT_APP_PORTAL_URL;


const goToPortal = (data) => {
    sendAnalyticsOutboundLink('https://billing.stripe.com/session/')
    window.location.replace(data.url);
}


const StripePortal = () => {
    return (
        <AjaxRequest url={portalUrl} method="POST" onSuccess={goToPortal} showBackdrop
                     analyticsEventArgs={{category: 'Stripe', action: 'Go to Stripe Billing Portal'}}/>
    )
}


StripePortal.propTypes = {
}

export default StripePortal;
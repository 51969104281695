import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import {capitalize} from "@mui/material/utils";
import SimpleTable from "./SimpleTable";
import {boolValue, convert, formatDate, linkWithIcon} from "../utils";


const downloadURL = process.env.REACT_APP_DOWNLOAD_URL


const columns = [
    {
        id: 'name',
        label: 'Name',
        minWidth: 250,
        maxWidth: 250,
        format: (value, row, classes) => linkWithIcon(value, row.url, GetAppIcon, classes)
    },
    {
        id: 'description',
        label: 'Description',
        minWidth: 300,
        maxWidth: 400,
        format: capitalize},
    {
        id: 'version',
        label: 'Version'},
    {
        id: 'os',
        label: 'Operating System',
        minWidth: 200,
        maxWidth: 200,
        format: (value) => convert(value, {
            'win': 'Windows',
            'linux': 'Linux / Unix',
            'macos': 'MacOS'
        }),
    },
    {
        id: 'architecture',
        label: 'Architecture',
        format: (value) => (value === "any" || value === "other") ? capitalize(value) : value,
    },
    {
        id: 'requires_admin',
        label: 'Requires Admin Access',
        format: boolValue,
        minWidth: 200,
        maxWidth: 200
    },
    {
        id: 'created_on',
        label: 'Uploaded',
        format: (value) => capitalize(formatDate(value)),
        minWidth: 250,
        maxWidth: 250
    },
    {
        id: 'md5sum',
        label: 'MD5 Checksum',
        minWidth: 290,
        maxWidth: 290
    },
];


const DownloadList = () => {
    return (
        <SimpleTable tableId="download-list" columns={columns} remoteUrl={downloadURL} title="Downloads"
                     description={[
                         'To use the decoder cli scripts and Monitoring Centre simulator sign up and subscribe to generate a license. Alternatively, click "Request Free Access" to get an evaluation license.',
                         'Click the filename to download the appropriate package for your system:']}/>
    )
}


DownloadList.propTypes = {

};

export default DownloadList;
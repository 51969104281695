import React, {useContext, useState} from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ReactMarkdown from "react-markdown";
import Box from "@mui/material/Box";
import EditIcon from '@mui/icons-material/Edit';
import CommentList from './CommentList'
import Tooltip from "@mui/material/Tooltip";
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import {Link as RouterLink, useParams } from "react-router-dom";
import CommentForm from "./CommentForm";
import FileList from "./FileList"
import AjaxConfirmationDialog from "./AjaxConfirmationDialog";
import EmailIcon from "@mui/icons-material/Email";
import { formatDate, isModified } from "../utils";
import {OpenChip, ProductChip, AssignedToChip, NoOwnerChip, ClosedByChip} from "./Chips";
import { UserContext, AjaxRequest, Paper, Typography } from "muistarter";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import PropTypes from "prop-types";


const issueURL = process.env.REACT_APP_ISSUE_URL;
const attachmentURL = process.env.REACT_APP_ISSUE_ATTACHMENT_URL;
const refreshInterval = parseInt(process.env.REACT_APP_ISSUE_VIEW_REFRESH_INTERVAL ) * 1000


const sxStyles = {
    root: {
            width: {sm: "100%", md: "90%"},
            marginLeft: {md: 6},
            marginTop: {md: 3},
            marginBottom: {md: 3}
    },
    paper: {
      paddingBottom: {md: 3},
  },
    mainGrid: {
        paddingTop: {md: 6},
        marginLeft: {md: 6},
        marginRight: {md:6},
        marginBottom: {md:3},
    },
  pagination: {
    color: 'primary.main'
  },
  chip: {
      marginTop: 1,
      textTransform: "capitalize"
  },
  chips: {
    '& > *': {
        margin: 2,
    },
    textTransform: "capitalize"
  }
}


const IssueTitle = props => {
    const {issue} = props;
    const user = useContext(UserContext).user;

    return (
        <>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
        <Grid item>
            <Typography id="issue-title" variant="h3">
                 {issue.title}
            </Typography>
        </Grid>
        <Grid item>
        <ProductChip product={issue.product}/>
        </Grid>
        <Grid item>
        {issue.closed_by ? <ClosedByChip closedBy={issue.closed_by.name}/> : <OpenChip/>}
        </Grid>
        <Grid item>
       {user && user.is_staff && !issue.owner && !issue.closed_by && <NoOwnerChip/>}
       {user && issue.owner && <AssignedToChip owner={issue.owner.id === user.id ? "You" : issue.owner.name}/>}
       </Grid>
       <Grid>
            {user && !user.is_staff && issue.email_notifications &&
                <Grid item>
                <Tooltip title="E-mail notifications enabled" aria-label="Email Notifications Enabled">
                    <EmailIcon id="email-icon" />
                </Tooltip>
                </Grid>
            }
       </Grid>
       </Grid>
       </>
    )
}


IssueTitle.propTypes = {
    issue: PropTypes.object
}


const cardSx = {
    cardHeader: {
        backgroundColor: "card.ownHeader",
        color: "primary.main"
    },
    cardContent: {
        backgroundColor: "secondary.light"
    },
    cards: {
        width:"100%",
        marginTop: 2,
        marginBottom: 2
    }
}


const getCloseSuccessMessage = (request, data) => {
    return "Issue closed";
}


const getClaimOwnershipSuccessMessage = (request, data) => {
    return "You are now the owner of this issue";
}


const IssueMain = props => {

    const user = useContext(UserContext).user;
    const {handleResult, issue} = props;
    const [closeDialogIsOpen, setCloseDialogIsOpen] = useState(false);
    const [claimOwnershipDialogIsOpen, setClaimOwnershipDialogIsOpen] = useState(false);

    const toggleClaimOwnershipDialogState = async() => {
        setClaimOwnershipDialogIsOpen(!claimOwnershipDialogIsOpen);
    }

    const toggleCloseDialogState = async() => {
        setCloseDialogIsOpen(!closeDialogIsOpen);
    }

    return (
        <>
            <Card id="issue-details" sx={cardSx.cards}>
                <CardHeader
                    id="issue-dates"
                    sx={cardSx.cardHeader}
                    title={`${user && user.user_id && issue.user && issue.user.id === user.user_id ? "You" : (issue.user ? issue.user.name: "Unknown user")} opened this issue ${formatDate(issue.created_on, true)}.`}
                    titleTypographyProps={{variant: "body2"}}
                    subheader={isModified(issue) && `Last modified ${formatDate(issue.modified_on, true)}`}
                    subheaderTypographyProps={{variant: "body2"}}
                    action={
                        <IconButton id="modify-issue"
                                    edge="end"
                                    aria-label="Edit issue"
                                    component={RouterLink}
                                    to={`/issues/modify/${issue.slug}`}
                                    size="small">
                            <EditIcon sx={cardSx.cardHeader}/>
                        </IconButton>
                    }
                />
                <CardContent id="issue-text" sx={cardSx.cardContent}>
                    <Typography variant="body2" component="span" color="primary">
                        <ReactMarkdown>
                            {issue.text}
                        </ReactMarkdown>
                    </Typography>
                </CardContent>
                <CardActions sx={cardSx.cardContent}>
                    {!issue.closed && <>
                    <Button
                        id="close-issue"
                        variant="contained"
                        color="secondary"
                        startIcon={<CloseIcon/>}
                        onClick={toggleCloseDialogState}
                    >
                        Close
                    </Button>
                    <AjaxConfirmationDialog open={closeDialogIsOpen}
                                            onSuccess={handleResult}
                                            onClose={toggleCloseDialogState}
                                            method="POST" url={`${issueURL}${issue.slug}/close/`}
                                            title="close-issue-dialog" dialogTitle={issue.title}
                                            dialogText="Are you sure you want to close this issue?"
                                            getSuccessMessage={getCloseSuccessMessage}
                                            analyticsEventArgs={{
                                                category: 'Issues',
                                                action: 'Close An Issue'
                                            }}
                    />
                    </>}
                    {user && user.is_staff && !issue.owner &&
                    <>
                        <Button
                            id="claim-ownership"
                            variant="contained"
                            color="secondary"
                            startIcon={<CloseIcon/>}
                            onClick={toggleClaimOwnershipDialogState}
                        >
                            Claim Ownership
                        </Button>
                        <AjaxConfirmationDialog open={claimOwnershipDialogIsOpen}
                                                onSuccess={handleResult}
                                                onClose={toggleClaimOwnershipDialogState}
                                                method="POST"
                                                url={`${issueURL}${issue.slug}/take_ownership/`}
                                                title="claim-ownership-dialog" dialogTitle={issue.title}
                                                dialogText="Are you sure you want to claim ownership of this issue?"
                                                getSuccessMessage={getClaimOwnershipSuccessMessage}
                                                analyticsEventArgs={{
                                                    category: 'Issues',
                                                    action: 'Take Ownership An Issue'
                                                }}
                        /></>}
                </CardActions>
            </Card>
            <Divider/>
        </>
    )
}


IssueMain.propTypes = {
    handleResult: PropTypes.func,
    issue: PropTypes.object
}


const IssueFileList = props => {
    const {issue, reload} = props;
    const createUrl = `${issueURL}${issue.slug}/add_attachments/`
    return (
         <FileList files={issue.attachment_set} onUpload={reload} onFilesRemoved={reload}
              uploadArea formID="issueAttachmentForm" method="POST" createUrl={createUrl}
              removeUrl={attachmentURL} showUsername desc="Attachment"
              analyticsEventArgs={{category: 'Issues', action: 'Add attachments'}}
              analyticsRemoveEventArgs={{category: 'Issues', action: 'Remove attachment'}}
         />
    )
}


IssueFileList.propTypes = {
    issue: PropTypes.object,
    reload: PropTypes.func,
}


const IssueComments = props => {
    const {issue, reload} = props;
    return (
       <>
         <CommentList comments={issue.comment_set} reload={reload} issue={issue}/>
         <CommentForm issue={issue} onSuccess={reload} restartFormOnSuccess
                       analyticsEventAction="Modify A Comment"/>
       </>
    )
}


IssueComments.propTypes = {
    issue: PropTypes.object,
    reload: PropTypes.func,
}


const IssueView = props => {

    const [issue, setIssue] = useState(null);
    const [ajaxKey, setAjaxKey] = useState(0);

    const { slug } = useParams();

    const remoteUrl = `${issueURL}${slug}/`;

    const handleResult = (data) => {
        setIssue(data)
    }

    const reload = (data) => {
        if (issue && data && data.slug === issue.slug){
            handleResult(data)
        }else {
            setAjaxKey(ajaxKey + 1);
        }
    }

    return (
        <>
        <AjaxRequest key={ajaxKey} url={remoteUrl} method="GET" checkLocationState
                     onSuccess={handleResult} runAtInterval={refreshInterval} showBackdrop
                     analyticsEventArgs={{category: 'Issues', action: 'Get Issue'}}/>
        <Box sx={sxStyles.root}>
        <Paper sx={sxStyles.paper}>
            {issue &&
                <Box id="issue-view" sx={sxStyles.mainGrid}>
                <IssueTitle issue={issue}/>
                <Grid container direction="row">
                    <Grid item xs={12} md={5}>
                         <Grid container direction="column">
                            <Grid item xs={12} md={11}>
                                <IssueMain issue={issue} handleResult={handleResult}/>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                 <IssueComments issue={issue} reload={reload}/>
                            </Grid>
                         </Grid>
                    </Grid>
                    <Grid item xs={12} md={7}>
                          <IssueFileList issue={issue} reload={reload}/>
                    </Grid>
                </Grid>
                </Box>}
        </Paper>
        </Box>
    </>
    );
}


IssueView.propTypes = {
}

export default IssueView;
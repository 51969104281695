import { parseBool, mockAuthenticationBackend, mock, mockBackendCheckIsStaff, mockBackendDecodeAccessTokenFromAxiosConfig } from 'muistarter'
import { defaultHex } from './components/HexDecoder'

const responseKey = process.env.REACT_APP_GENERAL_RESPONSE_KEY
const header = 'Authorization';

const userResponse = {id: 1, name: 'test user'}
const staffUserResponse = {id: 2, name: 'Admin Staff'}


function stringToSlug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}


function getRelatedUserData(config){
    const userData = mockBackendDecodeAccessTokenFromAxiosConfig(config);
    return {id: userData.id, name: `${userData.first_name} ${userData.last_name}`}
}


const issueListPage1 = [
             {slug: "test-user-closed-issue-202006301915", user: userResponse, owner: staffUserResponse,
                 created_on: '2020-06-30T19:15:56.648260Z', modified_on: '2020-07-01T19:20:56.648260Z',
                 title: 'Closed Issue', product: 'website', closed: true, closed_by: staffUserResponse,
                 closed_on: '2020-07-01T19:20:56.648260Z', email_notifications: false, unread_comments: 0},
            {slug: "test-user-issue-without-comments-202007011915", user: userResponse, owner: '', created_on: '2020-07-01T19:15:56.648260Z',
             modified_on: '2020-07-01T19:15:56.648260Z', title: 'Issue without comments', product: 'online',
             closed: false, closed_by: null, closed_on: null, email_notifications: true, unread_comments: 0}
             ]

const issueListPage2 = [
            {slug: "test-user-issue-with-comments-202007011915", user: userResponse, owner: staffUserResponse, created_on: '2020-07-01T19:15:55.630482Z',
             modified_on: '2020-07-01T19:15:55.630482Z', title: 'Issue with comments', product: 'download',
                closed: false, closed_by: null, closed_on: null, email_notifications: true, unread_comments: 2},
]


const issueListResponsePage1 = {
    count: issueListPage1.length + issueListPage2.length,
    next: null,
    previous: null,
    results: issueListPage1
}

const issueListResponsePage2 = {
    count: issueListPage1.length + issueListPage2.length,
    next: null,
    previous: null,
    results: issueListPage2
}


function addIssue(config){
    if (config.headers[header] && config.headers[header].length > 0){
        const issue = {...JSON.parse(config.data) }
        Object.assign(issue, {
            slug: "test-user-newly-created-issue-202006302020",
            user: userResponse,
            created_on: '2020-06-30T19:20:20.648260Z',
            modified_on: '2020-06-30T19:20:20.648260Z',
            closed: false,
            closed_by: null,
            closed_on: null,
            unread_comments: 0
        })
        let issueDetails = {...issue, comment_set: [], attachment_set: [] }
        issue4Text = issueDetails.text;
        issueListPage2.unshift(issueListPage1.pop());
        issueListPage1.unshift(issue);
        return [201, issueDetails];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function addOneIssue(){
    const title = 'Issue added with add one issue'
    const issue = {
        slug: "test-user-newly-created-issue-202006302020",
        title: title,
        email_notifications: true,
        product: 'other',
        user: userResponse,
        created_on: '2020-06-30T19:18:56.648260Z',
        modified_on: '2020-06-30T19:18:56.648260Z',
        closed: false,
        closed_by: null,
        closed_on: null,
        unread_comments: 0,
    }
    issueListPage2.unshift(issueListPage1.pop());
    issueListPage1.unshift(issue);
}


function getIssueDetails(issuePage, issueIndex, commentSet, attachmentSet, issueText) {
    let issue = { ...issuePage[issueIndex]};
    issue.text = issueText;
    issue.comment_set = commentSet;
    issue.attachment_set = attachmentSet;
    return [200, issue]
}

const issue1Text = 'I have comments!\n```python\nprint("abc")\n```';
const issue2Text = 'This issue has no comments';
const issue3Text = 'This issue was closed!';


function getIssueDetails1(config){
    return getIssueDetails(issueListPage2, 0, comments, attachments, issue1Text)
}

function getIssueDetails2(config) {
    return getIssueDetails(issueListPage1, 1, [], attachments2, issue2Text)
}

function getIssueDetails3(config){
    return getIssueDetails(issueListPage1, 0, [], [], issue3Text)
}


let issue4Text = '';


function getIssueDetails4(config){
    return getIssueDetails(issueListPage1, 0, [], [], issue4Text);
}


const modifyTimestamp = '2020-08-01T19:20:56.648260Z';


function updateIssue(config, changes, issuePage, issueIndex, commentSet, attachmentSet, issueText){
    changes.modified_on = modifyTimestamp;
    if (config.headers[header] && config.headers[header].length > 0){
        Object.assign(issuePage[issueIndex], changes);
        return getIssueDetails(issuePage, issueIndex, commentSet, attachmentSet, issueText)
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function modifyOneIssue(){
    const issue = issueListPage2[0];
    issue.modified_on = modifyTimestamp;
    issue.title = "Issue has been modified"
}


function modifyIssue(config){
    let changes = {...JSON.parse(config.data) }
    return updateIssue(config, changes, issueListPage2, 0, comments, attachments, issue1Text);
}


function closeIssue(config, normalReply, staffReply){
    let changes = {closed: true, closed_on: modifyTimestamp, closed_by: getRelatedUserData(config)}
    return updateIssue(config, changes, issueListPage2, 0, comments, attachments, issue1Text)
}


function takeOwnership(config, normalReply, staffReply){
    let changes = {owner: staffUserResponse}
    return updateIssue(config, changes, issueListPage1, 1, [], [], issue2Text)
}

const paginatedQuery = {page_size: 2, page: 1}
const paginatedQuery2 = {page_size: 2, page: 2}
const searchQuery = {search: 'without', ...paginatedQuery}

const issueSearchResult = [
            {slug: "test-user-issue-without-comments-202007011915", user: userResponse, owner: '',
                created_on: '2020-07-01T19:15:56.648260Z', modified_on: '2020-07-01T19:15:56.648260Z',
                title: 'Issue without comments', product: 'online', closed: false, closed_by: null,
                closed_on: null, email_notifications: false, unread_comments: 0},
        ]

const issueSearchResponse = {
    count: issueSearchResult.length,
    next: null,
    previous: null,
    results: issueSearchResult
}


const comments = [
    {slug: 'admin-staff-issue-with-comments-20200703184912', user: staffUserResponse, created_on:"2020-07-03T18:49:12.886308Z", modified_on:"2020-07-03T18:49:12.886308Z", text: "Hello", read: true},
    {slug: 'test-user-issue-with-comments-20200703184913', user: userResponse, created_on:"2020-07-03T18:49:13.911220Z", modified_on:"2020-07-03T18:49:13.911220Z", text: "Hi", read: true},
    {slug: 'test-user-issue-with-comments-20200703184914', user: userResponse, created_on:"2020-07-03T18:49:14.916207Z", modified_on:"2020-07-03T18:49:14.916207Z", text: "Hi Again", read: false},
    {slug: 'admin-staff-issue-with-comments-20200703184915', user: staffUserResponse, created_on:"2020-07-03T18:49:15.919249Z", modified_on:"2020-07-03T18:49:15.919249Z", text: "Goodbye\n```python\nprint('abc')\n", read: false},
    {slug: 'admin-staff-issue-with-comments-20200703184916', user: staffUserResponse, created_on:"2020-07-03T18:49:16.122695Z", modified_on:"2020-07-03T18:49:16.122695Z", text: "Goodbye", read: false}
    ]


const attachments = [
    {slug: 'test-user-logo512png-202007031849', user: userResponse, created_on: "2020-07-03T18:49:12.886308Z", description: null,
       url: "/images/logo512.png", name: 'logo512.png'},
    {slug: 'admin-staff-robotstxt-202007041849', user: staffUserResponse, created_on: "2020-07-04T18:49:12.886308Z", description: null,
       url: "/robots.txt", name: 'robots.txt'}
];


const attachments2 = [
    {slug: 'test-user-robotstxt-202007041850', user: userResponse, created_on: "2020-07-04T18:50:12.886308Z", description: null,
       url: "/robots.txt", name: 'robots.txt'}
];

function addComment(config){
    if (config.headers[header] && config.headers[header].length > 0){
        let comment = {...JSON.parse(config.data) }
        Object.assign(comment, {
            slug: stringToSlug(`${userResponse.name} issue-with-comments 20200704191856`),
            user: userResponse,
            created_on: '2020-07-04T19:18:56.648260Z',
            modified_on: '2020-07-04T19:18:56.648260Z',
            read: false
        })
        delete comment.issue;
        comments.push(comment);
        return [201, getIssueDetails1(config)[1]];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


const sleep = (value) =>
  new Promise((resolve) => setTimeout(resolve, value));


function getUploadProgressEvent(progress, total){
    return { lengthComputable: true, loaded: total * progress, total: total, type: "progress"}
}


async function addAttachments(config){
    config.data = null;
    if (config.headers[header] && config.headers[header].length > 1){
        const total = 1024; // mocked file size
        for (const progress of [0, 0.33, 0.66, 1]) {
            await sleep(500);
            if (config.onUploadProgress) {
                config.onUploadProgress(getUploadProgressEvent(progress, total));
            }
        }
        const filename = "logo512.png"
        let attachment = {
            slug: stringToSlug(`${userResponse.name} ${filename} 202010041918`),
            user: staffUserResponse,
            description: null,
            url: `/images/${filename}`,
            name: filename,
            created_on: '2020-10-04T19:18:56.648260Z',
        }
        attachments2.push(attachment);
        return [201, getIssueDetails2(config)[1]];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function deleteAttachment(config){
    if (config.headers[header] && config.headers[header].length > 0){
        attachments.shift();
        return [204, null];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function updateComment(config, changes, commentIndex){
    if (config.headers[header] && config.headers[header].length > 0){
        Object.assign(comments[commentIndex], changes);
        comments[commentIndex].modified_on = '2020-07-11T19:15:55.630482Z'
        return [200, comments[commentIndex]];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function modifyComment2(config){
    let changes = {...JSON.parse(config.data) }
    return updateComment(config, changes, 1)
}



function modifyComment(config){
    let changes = {...JSON.parse(config.data) }
    return updateComment(config, changes, 0)
}

const issueDetails2 = {slug: "test-user-issue-without-comments-202007011915", user: userResponse, owner: '', created_on: '2020-07-01T19:15:56.648260Z',
             modified_on: '2020-07-01T19:15:56.648260Z', title: 'Issue without comments', product: 'online', text: 'This issue has no comments',
             closed: false, closed_by: null, closed_on: null,
             email_notifications: true, unread_comments: 0, comment_set: [], attachment_set: attachments2}


const issueDetails3 = {slug:  "test-user-closed-issue-202006301915", user: userResponse, owner: staffUserResponse,
             created_on: '2020-06-30T19:15:56.648260Z', modified_on: '2020-07-01T19:15:56.648260Z',
             title: 'Closed Issue', text: 'This issue was closed!',  product: 'website', closed: true,
             closed_by: staffUserResponse, closed_on: '2020-07-01T19:20:56.648260Z',
             email_notifications: false, unread_comments: 0, comment_set: [], attachment_set: []}


const getIssueDetailsChangeable = (issueDetails) => {
    return [200, {title: issueDetails.title, product: issueDetails.product, text: issueDetails.text,
             email_notifications: issueDetails.email_notifications}]
}


const getIssueDetailsChangeable1 = () => {
    let issueDetails = getIssueDetails1();
    return getIssueDetailsChangeable(issueDetails[1])
}


const getIssueDetailsChangeable2 = () => {
    return getIssueDetailsChangeable(issueDetails2)
}


const getIssueDetailsChangeable3 = () => {
    return getIssueDetailsChangeable(issueDetails3)
}


const etsi232Hex = defaultHex.AUTO_DETECT_HI;
const etsi232HexResponse = {
    result: `pSHeader:
  communicationIdentifier:
    deliveryCountryCode: US
    networkIdentifier:
      operatorIdentifier: OPER
  lawfulInterceptionIdentifier: '12345678'
  li-psDomainId: [0, 4, 0, 2, 2, 5, 1, 14]
  sequenceNumber: 0
  timeStamp: ['2019', '12', 08, '21', '25', '49', '691', null]
  timeStampQualifier: timeOfMediation
payload:
- hI1-Operation
- - liActivated
  - communicationIdentifier:
      network-Identifier:
        operator-Identifier: OPER
    domainID: [0, 4, 0, 2, 2, 0, 1, 6]
    lawfulInterceptionIdentifier: '12345678'
    timeStamp:
    - localTime
    - generalizedTime: ['2019', '12', 08, '21', '25', '49', '691', null]
      winterSummerIndication: notProvided

    `
}


const etsi232ResultAttrs = {result: `Timestamp: 2019-12-08 21:25:49.691000
Operator Identifier: OPER
Liid: 12345678
Cin: 
Sequence Number: 0
Network Element Identifier: 
Event Type: liActivated
Payload Len: 0

`}

const etsi232ResultRows = {result: "2019-12-08 21:25:49.691000\tOPER\t12345678\t\t0\t\tliActivated\t0"}


const binaryRequests = [
    {slug: 'test-user-output_binary_file2txt-20200107010000', created_on: '2020-01-07T01:00:00Z', queued_on: '2020-01-07T00:00:02Z', output_format: 'yaml',
     processed_on: null, completed_on: null, standard: 'ETSI232',  request_type: 'binary_files', error: null,
        url: null, name: 'output_binary_file2.txt'},
    {slug: 'test-user-output_binary_filetxt-20200107000100', created_on: '2020-01-07T00:01:00Z', queued_on: '2020-01-07T00:00:02Z',  output_format: 'attrs',
     processed_on: '2020-01-07T00:00:04Z', completed_on: null, standard: 'ETSI232',
     request_type: 'binary_files', error: null, url: null, name: 'output_binary_file.txt'},
    {slug: 'test-user-robotstxt-20200107000000', created_on: '2020-01-07T00:00:00Z', queued_on: '2020-01-07T00:00:02Z',
     processed_on: '2020-01-07T00:00:04Z', completed_on: '2020-01-07T00:00:34Z', standard: null, output_format: 'yaml',
     request_type: 'binary_files', error: 'length prefix too long, 67 bytes', url: null, name: 'robots.txt'},
    {slug: 'test-user-robotstxt-20200108000000', created_on: '2020-01-08T00:00:00Z', queued_on: '2020-01-07T00:00:02Z',
     processed_on: '2020-01-07T00:00:04Z', completed_on: '2020-01-07T00:00:34Z', standard: null, output_format: 'rows',
     request_type: 'binary_files', error: null, url: "/robots.txt", name: 'robots.txt'}
]


const finishRequests = (requests)  => {
    const completed_on = '2020-05-07T00:00:34Z';
    const url = "/robots.txt";
    requests.forEach((request) => {
        if (!request.completed_on) {
            request.completed_on = completed_on;
            request.url = url;
        }
    })
}


const finishBinaryRequests = () => finishRequests(binaryRequests);
const finishPcapRequests = () => finishRequests(pcapRequests);


const getBinaryRequests = () => {
    return [200, {count: binaryRequests.length, results: binaryRequests, previous: null, next: null}];
}

const pcapRequests = [
     {slug: 'test-user-output_pcap_file2txt-20200107000000', created_on: '2020-01-07T01:00:00Z', queued_on: '2020-01-07T00:00:02Z', output_format: 'yaml',
     processed_on: null, completed_on: null, standard: 'ETSI232',  request_type: 'pcap_files', error: null,
        url: null, name: 'output_pcap_file2.txt'},
    {slug: 'test-user-output_pcap_filetxt-20200107000000', created_on: '2020-01-07T00:01:00Z', queued_on: '2020-01-07T00:00:02Z',  output_format: 'attrs',
     processed_on: '2020-01-07T00:00:04Z', completed_on: null, standard: 'ETSI232',
     request_type: 'pcap_files', error: null, url: null, name: 'output_pcap_file.txt'},
    {slug: 'test-user-robots-pcaptxt-20200107000000', created_on: '2020-01-07T00:00:00Z', queued_on: '2020-01-07T00:00:02Z',
     processed_on: '2020-01-07T00:00:04Z', completed_on: '2020-01-07T00:00:34Z', standard: null, output_format: 'yaml',
     request_type: 'pcap_files', error: 'length prefix too long, 67 bytes', url: null, name: 'robots.txt'},
    {slug: 'test-user-robots-pcaptxt-20200108000000', created_on: '2020-01-08T00:00:00Z', queued_on: '2020-01-07T00:00:02Z',
     processed_on: '2020-01-07T00:00:04Z', completed_on: '2020-01-07T00:00:34Z', standard: null, output_format: 'rows',
     request_type: 'pcap_files', error: null, url: "/robots.txt", name: 'robots.txt'}
]


const getPcapRequests = () => {
    return [200, {count: pcapRequests.length, results: pcapRequests, previous: null, next: null}];
}


async function addDecodeRequest(config){
    if (config.headers[header] && config.headers[header].length > 0){
        const data = config.data;
        const total = 1024; // mocked file size
        for (const progress of [0, 0.5, 1]) {
            await sleep(500);
            if (config.onUploadProgress) {
                config.onUploadProgress(getUploadProgressEvent(progress, total));
            }
        }
        let request = {slug: 'test-user-new-decode-requesttxt-20200108000000', created_on: '2020-01-08T00:00:00Z',
            queued_on: '2020-01-08T00:00:02Z', processed_on: '2020-01-08T00:00:04Z', completed_on: null,
            standard: data.get('standard'), request_type: data.get('request_type'),
            output_format: data.get('output_format'),error: null, 'url': null, name: data.get('output_filename')}
        const request_type = request.request_type;
        if (request_type === "binary_files"){
            request = {...request, typical_processing_time: 0.5}
            binaryRequests.unshift(request);
        }else if (request_type === "pcap_files"){
            request = {...request, typical_processing_time: 10}
            pcapRequests.unshift(request);
        }
        else{
            return [400, `Invalid Request Type ${data.request_type}`]
        }
        return [201, request];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function deleteBinaryDecodeRequest(config){
    if (config.headers[header] && config.headers[header].length > 0){
        binaryRequests.pop();
        return [204, null];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function deletePcapDecodeRequest(config){
    if (config.headers[header] && config.headers[header].length > 0){
        pcapRequests.pop();
        return [204, null];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


const stripePortalUrl = 'https://billing.stripe.com/session/5Y4FSokKSET6rd2HyrhMYV2EsxnTS2v3';
const stripePriceIdYearly = "price_1HVzEECz06et8Vuz7lgddpaZ";
const stripePriceIdMonthly = "price_1HVnQzCz06et8VuzBjBpK2fb";
const stripePriceIdYearlyServer = "price_1JB9PtCz06et8VuzfLu1Z9bf";
const stripeCheckoutSessionIdYearly = 'cs_test_PDxz4a0xBl7UbvcMTXwHCV44NdKiTZkPqBkxIx6fIQR6ohzv5Nm0NI6u';
const stripeCheckoutSessionIdMonthly = 'cs_test_Gz1f8epKdMb1YrTyY6z3me5ZcwC0w10R2rxCyx5SXbv2U8bcdAcr6TjG';
const decoderPriceMetadata = {'additional_info': 'MC Simulator Not Included', price_header: 'Decoders'}
const serverPriceMetadata = {'additional_info': 'Includes MC Simulator', price_header: 'Decoders & MC Simulator'}


const prices = [
     {
        id: stripePriceIdMonthly,
        metadata: decoderPriceMetadata,
        subscription_info: {sub_id: null, cancel_at: null, current_period_end: null},
        recurring: {
            aggregate_usage: null,
            interval: "month",
            interval_count: 1,
            trial_period_days: null,
            usage_type: "licensed"
        },
        type: "recurring",
        unit_amount: 2499,
        unit_amount_decimal: "2499"
    },
    {
        id: stripePriceIdYearly,
        metadata: decoderPriceMetadata,
        subscription_info: {sub_id: null, cancel_at: null, current_period_end: null},
        recurring: {
            aggregate_usage: null,
            interval: "year",
            interval_count: 1,
            trial_period_days: null,
            usage_type: "licensed"
        },
        type: "recurring",
        unit_amount: 29999,
        unit_amount_decimal: "29999"
    },
    {
        id: stripePriceIdYearlyServer,
        metadata: serverPriceMetadata,
        subscription_info: {sub_id: null, cancel_at: null, current_period_end: null},
        recurring: {
            aggregate_usage: null,
            interval: "year",
            interval_count: 1,
            trial_period_days: null,
            usage_type: "licensed"
        },
        type: "recurring",
        unit_amount: 99999,
        unit_amount_decimal: "99999"
    },
];


let sub_id = "sub_IPuJfs49Gcejag";


const getPricesSubscribed = () => {
    const subscribedPrices = [{...prices[0]}, {...prices[1]}]
    subscribedPrices[0].subscription_info = {sub_id: sub_id, cancel_at: null, current_period_end: 1637001731};
    return [200, subscribedPrices];
}



const getPricesNotSubscribed = () => {
    return [200, prices];
}


const getPrices = (config) => {
    return mockBackendCheckIsStaff(config, getPricesSubscribed, getPricesNotSubscribed, getPricesNotSubscribed);
}


let cancelAt = 2232570371000;


function isSubscribed(config){
    return mockBackendCheckIsStaff(config, [200, {
        sub_id: sub_id, cancel_at: cancelAt, current_period_end: null, limit: 1, evaluation: false,
            price_id: stripePriceIdMonthly, remaining:  {binary_files: null, pcap_files: null}}],
        [200, {
        sub_id: null, cancel_at: null, current_period_end: null, limit: 1, evaluation: false,
            price_id: null, remaining: {binary_files: 0, pcap_files: 1}
    }]);
}


function changeCancelAtTimeOneHour(){
    cancelAt = new Date(new Date().getTime() + 3600000).getTime();
}


function unsubscribeNow (){
    sub_id = null;
    return [200, {[responseKey]: 'Subscription has been cancelled.'}];
}



function unsubscribe(config){
    return  mockBackendCheckIsStaff(config, [200, unsubscribeNow()], [200, null]);
}


const downloadSlug = 'testfile-10-win-20200107'
const downloadSlug2 = 'testfile-10-linux-20200107'
const downloadFileName =  "logo512.png"
const downloadUrl =  "/images/logo512.png"

const downloadResult =  [
            {slug: downloadSlug, title: 'testfile', created_on: '2020-01-07T00:00:00Z',
             description: 'Test description', version: '2.0.0', os: 'win',
             architecture: 'x86_64', requires_admin: true, md5sum: '6abff61e952d976a97c69e2fe139334c',
             name: downloadFileName, url: downloadUrl},
            {slug: downloadSlug2, title: 'testfile linux', created_on: '2020-01-07T00:00:00Z',
             description: 'Portable tarfile for Linux and Unix', version: '2.0.0', os: 'linux',
             architecture: "any", requires_admin: false, md5sum: '344048009fd32b8d2dd9fee537a27744',
             name: downloadFileName, url: downloadUrl}]


const downloadListResponse = {
    count: downloadResult.length,
    next: null,
    previous: null,
    results: downloadResult
}


const licenseSlug = 'test-user-20200107000000'
const licenseFilename = "limc_20210715071857_d_cuyZNwKMS7nLqwCv26Tg==.txt"
const licenseDownloadUrl = "/robots.txt"


const licenseResult = [
    {
        machine_key: 'Z5LyvjMvnC3DY6ZxVMpKLQ==', generated_on: '2020-01-07T00:00:00Z',
        expires: '2021-01-07T09:34:17Z', evaluation: false, features: 'lidecode,lishark,liserver', 'version': 2,
        slug: licenseSlug, name: licenseFilename, url: licenseDownloadUrl
    }]


const licenseListResponse = {
    count: licenseResult.length,
    next: null,
    previous: null,
    results: licenseResult
}



function addLicense(config){
    if (config.headers[header] && config.headers[header].length > 0){
        const license = {...JSON.parse(config.data) }
        delete license.password;
        Object.assign(license, {
            slug: 'test-user-20210107000000',
            user: userResponse,
            generated_on: '2021-06-30T19:20:20.648260Z',
            expires: '2021-04-30T19:20:20.648260Z',
            evaluation: true,
            features: 'lidecode,lishark',
            version: 2,
            name: licenseFilename,
            url: licenseDownloadUrl
        })
        licenseResult.push(license);
        return [201, null];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function deleteLicense(config){
    if (config.headers[header] && config.headers[header].length > 0){
        licenseResult.shift();
        return [204, null];
    }
    return [401, {[responseKey]: 'Authentication credentials were not provided.'}]
}


function modifyOneLicense(){
    licenseResult[0].name = "robots2.txt";
}


export default function mockBackend() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production' || parseBool(process.env.REACT_APP_MOCK_BACKEND)) {
        mock.onGet(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-with-comments-202007011915/`).reply(getIssueDetails1)
        mock.onGet(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-without-comments-202007011915/`).reply(getIssueDetails2)
        mock.onGet(`${process.env.REACT_APP_ISSUE_URL}test-user-closed-issue-202006301915/`).reply(getIssueDetails3)
        mock.onGet(`${process.env.REACT_APP_ISSUE_URL}test-user-newly-created-issue-202006302020/`).reply(getIssueDetails4)
        mock.onPost(process.env.REACT_APP_ISSUE_URL).reply(addIssue);
        mock.onGet(process.env.REACT_APP_ISSUE_URL, {params: searchQuery}).reply(200, issueSearchResponse);
        mock.onGet(process.env.REACT_APP_ISSUE_URL, {params: paginatedQuery}).reply(200, issueListResponsePage1)
        mock.onGet(process.env.REACT_APP_ISSUE_URL, {params: paginatedQuery2}).reply(200, issueListResponsePage2)
        mock.onGet(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-with-comments-202007011915/?changeable`).reply(getIssueDetailsChangeable1)
        mock.onGet(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-without-comments-202007011915/?changeable`).reply(getIssueDetailsChangeable2)
        mock.onGet(`${process.env.REACT_APP_ISSUE_URL}test-user-closed-issue-202006301915/?changeable`).reply(getIssueDetailsChangeable3)
        mock.onPost(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-with-comments-202007011915/close/`).reply(closeIssue)
        mock.onPost(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-without-comments-202007011915/take_ownership/`).reply(takeOwnership);
        mock.onPut(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-with-comments-202007011915/`).reply(modifyIssue);
        mock.onPut(`${process.env.REACT_APP_ISSUE_URL}test-user-closed-issue-202006301915/`).reply(200, issueDetails3);
        mock.onPost(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-with-comments-202007011915/add_comment/`).reply(addComment);
        mock.onPut(`${process.env.REACT_APP_COMMENT_URL}admin-staff-issue-with-comments-20200703184912/`).reply(modifyComment);
        mock.onPut(`${process.env.REACT_APP_COMMENT_URL}test-user-issue-with-comments-20200703184913/`).reply(modifyComment2);
        mock.onPost(`${process.env.REACT_APP_ISSUE_URL}test-user-issue-without-comments-202007011915/add_attachments/`).reply(addAttachments);
        mock.onDelete(`${process.env.REACT_APP_ISSUE_ATTACHMENT_URL}test-user-logo512png-202007031849/`).reply(deleteAttachment);
        mock.onPost(process.env.REACT_APP_HEX_DECODE_URL, {binary: etsi232Hex, standard: 'AUTO_DETECT_HI', output_format: 'yaml'}).reply(200, etsi232HexResponse);
        mock.onPost(process.env.REACT_APP_HEX_DECODE_URL, {binary: etsi232Hex, standard: 'ETSI232', output_format: 'yaml'}).reply(200, etsi232HexResponse);
        mock.onPost(process.env.REACT_APP_HEX_DECODE_URL, {binary: etsi232Hex, standard: 'ETSI232', output_format: 'attrs'}).reply(200, etsi232ResultAttrs);
        mock.onPost(process.env.REACT_APP_HEX_DECODE_URL, {binary: etsi232Hex, standard: 'ETSI232', output_format: 'rows'}).reply(200, etsi232ResultRows);
        mock.onPost(process.env.REACT_APP_HEX_DECODE_URL, {binary: etsi232Hex, standard: '3GPP_HI2', output_format: 'yaml'}).reply(400, {[responseKey]: 'Failed to decode this pdu.'});
        mock.onPost(process.env.REACT_APP_FILE_DECODE_URL).reply(addDecodeRequest);
        mock.onDelete(`${process.env.REACT_APP_FILE_DECODE_URL}test-user-robotstxt-20200108000000/`).reply(deleteBinaryDecodeRequest);
        mock.onDelete(`${process.env.REACT_APP_FILE_DECODE_URL}test-user-robots-pcaptxt-20200108000000/`).reply(deletePcapDecodeRequest);
        mock.onGet(process.env.REACT_APP_FILE_DECODE_URL, {params: {request_type: 'binary_files'}}).reply(
            getBinaryRequests);
        mock.onGet(process.env.REACT_APP_FILE_DECODE_URL, {params: {request_type: 'pcap_files'}}).reply(
            getPcapRequests);
        mock.onPost(process.env.REACT_APP_SUBSCRIPTION_URL, {price_id: stripePriceIdYearly}).reply(200, {sessionId: stripeCheckoutSessionIdYearly});
        mock.onPost(process.env.REACT_APP_SUBSCRIPTION_URL, {price_id: stripePriceIdMonthly}).reply(200, {sessionId: stripeCheckoutSessionIdMonthly});
        mock.onGet(process.env.REACT_APP_SUBSCRIPTION_URL).reply(isSubscribed);
        mock.onDelete(process.env.REACT_APP_SUBSCRIPTION_URL).reply(unsubscribe);
        mock.onGet(process.env.REACT_APP_PRICES_URL).reply(getPrices);
        mock.onPost(process.env.REACT_APP_PORTAL_URL).reply(201, {url: stripePortalUrl});

        mock.onGet(process.env.REACT_APP_DOWNLOAD_URL).reply(200, downloadListResponse);

        mock.onGet(process.env.REACT_APP_LICENSE_URL).reply(200, licenseListResponse);
        mock.onPost(process.env.REACT_APP_LICENSE_URL).reply(addLicense);
        mock.onDelete(`${process.env.REACT_APP_LICENSE_URL}${licenseSlug}/`).reply(deleteLicense);

        mockAuthenticationBackend();
        if(typeof window !== "undefined") {
            window.unsubscribe = unsubscribeNow;
            window.addOneIssue = addOneIssue;
            window.modifyOneIssue = modifyOneIssue;
            window.modifyOneLicense = modifyOneLicense;
            window.finishBinaryRequests = finishBinaryRequests;
            window.finishPcapRequests = finishPcapRequests;
            window.changeCancelAtTimeOneHour = changeCancelAtTimeOneHour;
        }
    }
}
import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import HomeText from "./HomeText";
import DecoderTabMenu from "./DecoderTabMenu";


const youtubeLink = process.env.REACT_APP_YOUTUBE_LINK;


const root = {
    display: "flex",
    width: {xs: "100%", md: "95%"},
    marginTop: {'md': 3},
    marginLeft: {'md': 3},
    marginBottom: {'md': 3},
}


const IframeWithoutBorder = styled('iframe')(() => ({
    border: 0,
    paddingTop: 10
}));



const Home = () => {

    const [preferences, setPreferences] = useState({});
    const [preferencesChecked, setPreferencesChecked] = useState({});
    const [key, setKey] = useState(1);
    const [tooltipKey, setTooltipKey] = useState(1);

    useEffect(() => {
        const startPreferences = JSON.parse(localStorage.getItem('preferences'));
        if (startPreferences && startPreferences !== {}) {
            setPreferences(startPreferences);
            setKey(key => key+1);
        }
        setPreferencesChecked(true);
    }, [])

    const reset = () => {
        localStorage.removeItem('preferences');
        setPreferences({});
        setKey(key+1);
        setTooltipKey(tooltipKey + 1);
    }

    const savePreferences = (newPreferences) => {
        let fullPreferences = {...preferences, ...newPreferences};
        setPreferences(fullPreferences);
        localStorage.setItem('preferences', JSON.stringify(fullPreferences));
    }

    const toggleFullScreen = () => {
        savePreferences({fullScreen: !preferences.fullScreen});
        setTooltipKey(tooltipKey + 1);
    }

    const { fullScreen } = preferences;

    return (
        <Box>
            <Grid container sx={root}>
                {!fullScreen && <Grid item xs={12} md={6}>
                    <HomeText/>
            </Grid>}
             <Grid item xs={12} md={fullScreen ? 12: 6}>
                    <DecoderTabMenu key={key} preferencesChecked={preferencesChecked}
                        preferences={preferences} tooltipKey={tooltipKey} reset={reset}
                    toggleFullScreen={toggleFullScreen} setPreferences={savePreferences}/>
                      <IframeWithoutBorder width="560" height="315" src={youtubeLink}
                            title="YouTube tutorial"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
            </Grid>
            </Grid>
        </Box>
    )
}


Home.propTypes = {
}

export default Home;

import React, {useContext, useState} from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar'
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from "@mui/material/Tooltip";
import theme from "../theme";
import {UserContext} from "muistarter";
import Link from '@mui/material/Link';
import AjaxConfirmationDialog from "./AjaxConfirmationDialog";
import FileUploadArea from "./FileUploadArea"
import PropTypes from "prop-types";
import {styled} from '@mui/material/styles'


const isImage = /.(bmp|jpg|jpeg|png|gif|svg)$/i


const StyledImage = styled('img')({
    height: 125,
    width: 125,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    color: 'primary.main'
})


const filename = {
    color: 'secondary.light'
}


const imageIcon =  {
    height: 125,
    width: 125,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    color: "primary.main"
}


const icon = {
    color: 'secondary.light'
}


const avatar = {
    backgroundColor: 'primary.main'
}



const ShowFullTextTooltip = props => {
    const { children, ...linkProps } = props;
    return (
        <Tooltip title={children}><Link {...linkProps}>{children}</Link></Tooltip>
    )
}


const Attachment = props => {

    const checkHasRemovePermission = (user, file) => {
        return (user && (user.is_staff || file.user.id === user.id))
    }

    let {desc="file", file, hasRemovePermission=checkHasRemovePermission, showAdditionalInfo, getIcon, url,
        analyticsRemoveEventArgs, onFilesRemoved} = props;

    const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);

    const changeRemoveDialogState = async() => {
        setRemoveDialogIsOpen(!removeDialogIsOpen)
    }

    const getRemoveSuccessMessage = (request, data) => {
        return `${desc} ${file.name} was successfully deleted`
    }

    const FileIcon = getIcon ? getIcon(file) : AttachFileIcon;

    const linkProps = {href: file.url, underline: "none", target: "_blank", sx: filename}
    const imageLinkProps = {...linkProps}

    const userDetails = useContext(UserContext);
    const user = userDetails.user;

    return (
            <>
            <Link {...imageLinkProps}>
            {isImage.test(file.name) ?
                <StyledImage
                    src={file.url}
                    alt={file.name}
                    loading="lazy"
                /> :
                <FileIcon sx={imageIcon}/>
            }
            </Link>
             <ImageListItemBar
                title={<ShowFullTextTooltip {...linkProps}>{file.name}</ShowFullTextTooltip>}
                subtitle={<span>{showAdditionalInfo ?
                        <ShowFullTextTooltip>{showAdditionalInfo(file, user)}</ShowFullTextTooltip>
                        : file.user.name}</span>}

                actionIcon={hasRemovePermission(user, file) &&
                <>
                        <IconButton id={`remove-file-${file.slug}`} onClick={changeRemoveDialogState}
                                    aria-label={`remove file ${file.slug}`}>
                        <Avatar sx={avatar}><DeleteForeverSharpIcon sx={icon} /></Avatar>
                        </IconButton>
                        <AjaxConfirmationDialog open={removeDialogIsOpen}
                                                onSuccess={onFilesRemoved}
                                                onClose={changeRemoveDialogState}
                                                method="DELETE"
                                                url={`${url}${file.slug}/`}
                                                title={`delete-attachment-dialog-${file.slug}`}
                                                dialogTitle={file.name}
                                                dialogText="Are you sure you want to delete this file?"
                                                getSuccessMessage={getRemoveSuccessMessage}
                                                analyticsEventArgs={analyticsRemoveEventArgs}
                        />
                </>
                }
                actionPosition="right"
            />
            </>
    )
}


Attachment.propTypes = {
    analyticsRemoveEventArgs: PropTypes.object,
    dec: PropTypes.string,
    file: PropTypes.object,
    getIcon: PropTypes.func,
    hasRemovePermission: PropTypes.func,
    onFilesRemoved: PropTypes.func,
    showAdditionalInfo: PropTypes.func,
    url: PropTypes.string
}



const fileListRoot = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'secondary.light'
}

const imageList = {
    width: 500,
    height: 500
}


const FileList = props => {

    let { files, id="attachment-list", width, uploadArea, createUrl, removeUrl, ...attachmentProps } = props;

    let columns = 3;
    try {
        if (useMediaQuery(theme.breakpoints.down('md'))) columns = 2;
    } catch {
    }
    return (
        <Box id={id} sx={fileListRoot}>
            <ImageList sx={imageList} cols={columns} rowHeight={125}>
              {files.map((file) => (
                <ImageListItem key={file.slug} cols={1} rows={1}>
                    <Attachment file={file} url={removeUrl }{...attachmentProps}/>
                </ImageListItem>
              ))}
              {uploadArea && <ImageListItem key="uploadArea" rows={2}>
                        <FileUploadArea url={createUrl} immediateUpload {...attachmentProps}/>
                    </ImageListItem>}
            </ImageList>
        </Box>
    )
}

FileList.propTypes = {
    createUrl: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
    removeUrl: PropTypes.string,
    uploadArea: PropTypes.bool,
    width: PropTypes.number
}


export default FileList;

import React, {useRef, useState} from 'react';

import {AjaxForm, required, Typography, toast} from "muistarter";
import { Field } from 'react-final-form'

import {DropzoneArea} from 'mui-file-dropzone';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";


const defaultFilesLimit = parseInt(process.env.REACT_APP_MAX_SIMULTANEOUS_ATTACHMENTS || 2);
const attachmentMaxSize = parseInt(process.env.REACT_APP_ATTACHMENT_MAX_SIZE_MB) * 1024 * 1024
const dropzoneAcceptedFiles = [];


const CircularProgressWithLabel = props => {
    const {closeToast, prefix, toastProps, value, ...circularProgressProps} = props;
  return (
      <>
      <Typography variant="caption" component="div" color="textSecondary">
            {prefix}
       </Typography>
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={value} {...circularProgressProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {value > 0 && <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          value,
        )}%`}</Typography>}
      </Box>
    </Box>
    </>
  );
}


CircularProgressWithLabel.propTypes = {
    prefix: PropTypes.string,
    value: PropTypes.number,
}


const copyToFormData = (obj) => {
    let fd = new FormData();
    Object.keys(obj).forEach(key => obj.hasOwnProperty(key) && key !== "files" && fd.append(key, obj[key]));
    obj.files.forEach(file => fd.append('files', file));
    return fd;
}

const addProgressToast = (filename) => {
    return toast(
        ({ closeToast }) => <CircularProgressWithLabel prefix={`Uploading ${filename}`}/>,
        {type: "info"}
    )
}


const updateProgressToast = (filename, toastId, uploadProgress) => {
    return toast.update(toastId, {
        type: "info",
        render: <CircularProgressWithLabel prefix={`Uploading ${filename}`} value={uploadProgress}/>
    }
)
}

const onUploadProgress = (filename, toastId, progressEvent) => {
    const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
    updateProgressToast(filename, toastId, uploadProgress);
}


const validateDefault = (values) => {
    return required(['files'], values);
}


const FileUploadForm = props => {

   let {acceptedFiles, maxFileSize, text, validate=validateDefault, formID, showPreviews, showPreviewsInDropzone,
        immediateUpload, onFilesChange, onUpload, showFileNames, showFileNamesInPreview, getUploadSuccessMessage,
        filesLimit, classes, children, ...formProps} = props


    const desc = useRef(null);
    const toastId = useRef(null);
    const [key, setKey] = useState(0);

    const setToastDescription = (values) => {
        const numFiles = values.files.length;
        const filename = values.files[0].name;
        if (numFiles > 1) desc.current =  `${numFiles} files`;
        else desc.current = filename;
    }

   const getToastOptions = (values) => {
        setToastDescription(values);
        toastId.current = addProgressToast(desc.current);
        return {toastId: toastId.current}
    }

   const adaptRequest = (request) => {
        request.data = copyToFormData(request.data);
        const config = {
           onUploadProgress: (event) => {
              onUploadProgress(desc.current, toastId.current, event);
           }
        }
        Object.assign(request, config);

        return request;
    }

    const onSuccess = (values, form, request) =>{
        setTimeout(() => setKey(key + 1), 0);
        if (onUpload) onUpload(values, form, request);
    }

    const onFilesChangeDefault = (files) => {
        if (files && files.length > 0 && immediateUpload){
            document.getElementById(formID)
                .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
    }

    onFilesChange = onFilesChange || onFilesChangeDefault;

   const getSuccessMessage = (request, responseData) => {
        let msg;
        let files = request.data.getAll('files');
        if (files.length > 1) msg = `${files.length} files have been successfully uploaded`;
        else msg = `File ${desc.current} has been successfully uploaded`;
        if (getUploadSuccessMessage) return getUploadSuccessMessage(request, responseData, msg);
        return msg;
    }

    filesLimit = filesLimit || defaultFilesLimit;
    showPreviews = !!showPreviews
    showPreviewsInDropzone = !!showPreviewsInDropzone
    showFileNames = !!showFileNames
    showFileNamesInPreview = !!showFileNamesInPreview

    return (
        <AjaxForm adaptRequest={adaptRequest} formID={formID || 'fileUploadForm'} validate={validate}
                  onSuccess={onSuccess} getSuccessMessage={getSuccessMessage}
                  getToastOptions={getToastOptions} {...formProps}>
            {children}
            <Field component={DropzoneAreaAdaptor} key={key} name="files"
               clearOnUnmount dropzoneText={text || "Drag and drop or click to add a file"}
               acceptedFiles={acceptedFiles || dropzoneAcceptedFiles} showPreviews={showPreviews}
                   showPreviewsInDropzone={showPreviewsInDropzone} showFileNames={showFileNames}
                   showFileNamesInPreview={showFileNamesInPreview} filesLimit={filesLimit}
               maxFileSize={maxFileSize || attachmentMaxSize} onFilesChange={onFilesChange}
            />
        </AjaxForm>
    )
}


FileUploadForm.propTypes = {
    acceptedFiles: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    filesLimit: PropTypes.number,
    formID: PropTypes.string,
    getUploadSuccessMessage: PropTypes.func,
    immediateUpload: PropTypes.bool,
    maxFileSize: PropTypes.number,
    onFilesChange: PropTypes.func,
    onUpload: PropTypes.func,
    showFileNames: PropTypes.bool,
    showFileNamesInPreview: PropTypes.bool,
    showPreviews: PropTypes.bool,
    showPreviewsInDropzone: PropTypes.bool,
    text: PropTypes.string,
    validate: PropTypes.func,
}


const PREFIX = "uploader";


const classes = {
    dropzone: `${PREFIX}-dropzone`,
    dropzoneText: `${PREFIX}-dropzoneText`,
    uploadIcon: `${PREFIX}-uploadIcon`,
}


const StyledDropzoneBox = styled("div")(({ theme }) => ({
    [`& .${classes.dropzone}`]: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
    },

    [`& .${classes.dropzoneText}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${classes.uploadIcon}`]: {
        color: theme.palette.primary.main,
    }
}))


const DropzoneAreaAdaptor = ({ input: { onChange, value, name }, onFilesChange, meta, ...rest }) => {
    return (
        <StyledDropzoneBox>
            <DropzoneArea inputProps={{name: name}}
                                dropzoneClass={classes.dropzone}
                                showAlerts={['error', 'info']}
                                dropzoneParagraphClass={classes.dropzoneText}
                                classes={{icon: classes.uploadIcon}}
                                onChange={files => {
                                    if (files && files.length > 0) {
                                        onChange(files);
                                        onFilesChange(files);
                                    }
                                }}
                          {...rest}/>
            {meta.submitError && <FormHelperText error>{meta.submitError}</FormHelperText>}
            {meta.submitFailed && meta.error && <FormHelperText required={meta.error==="Required"}
                                                         error>{meta.error}</FormHelperText>}
        </StyledDropzoneBox>
    )
}


export default FileUploadForm;
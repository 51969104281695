import React, {useContext} from "react";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden"
import Tabs from "@mui/material/Tabs";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import Tab from "@mui/material/Tab";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {SSRContext} from 'muistarter';
import PropTypes from "prop-types";


const ResponsiveTabMenu = props => {
    // Using jss to hide tabs causes css class name mismatch between client and server, so css better for SSR
    const {showTabsMobile, children, onChange, resetIcon, resetProps, fullScreenIcon, fullScreenProps, ...tabProps} = props;

    const initialWidth = useContext(SSRContext).initialWidth;

    return (
           <>
               <Hidden smDown implementation="css">
                   <Tabs orientation="horizontal" onChange={onChange} {...tabProps} textColor="inherit">
                         {children}
                       <Tab icon={resetIcon} {...resetProps}/>
                       <Tab icon={fullScreenIcon} {...fullScreenProps}/>
                   </Tabs>
               </Hidden>
            <Box alignSelf="flex-end">
               <Hidden smUp initialWidth={initialWidth}>
                 <IconButton
                    color="inherit" onClick={(e) => onChange(e, 3)}
                    edge="start" {...resetProps}>{resetIcon}
                 </IconButton>
                 <IconButton
                    color="inherit" onClick={(e) => onChange(e, 4)}
                    edge="start" {...fullScreenProps}>{fullScreenIcon}
                 </IconButton>
                 <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={(e) => onChange(e, 5)}
                    >
                    <MenuIcon />
                    </IconButton>
                    <SwipeableDrawer anchor="right" onClose={(e) => onChange(e, 5)}
                                     onOpen={() => {}} open={showTabsMobile}>
                   <Tabs orientation="vertical" onChange={onChange} {...tabProps} textColor="inherit">
                       {children}
                   </Tabs>
                   </SwipeableDrawer>
               </Hidden>
               </Box>
           </>
    )
}

ResponsiveTabMenu.propTypes = {
    showTabsMobile: PropTypes.bool,
    children: PropTypes.node,
    onChange: PropTypes.func,
    resetIcon: PropTypes.node,
    resetProps: PropTypes.object,
    fullScreenIcon: PropTypes.node,
    fullScreenProps: PropTypes.object
}

export default ResponsiveTabMenu;
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import { CacheProvider } from '@emotion/react';
import * as serviceWorker from './serviceWorker';
import mockBackend from "./modules/backend";
import createEmotionCache from './createEmotionCache';
import {parseBool} from 'muistarter';


export const selector = "#root";


const Main = () => {
    const cache = createEmotionCache();

  return (
    //<React.StrictMode>
       <CacheProvider value={cache}>
    <Router>
      <App />
    </Router>
       </CacheProvider>
    //</React.StrictMode>
  );
}

//const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate  // to be updated for SSR


if (parseBool(process.env.REACT_APP_MOCK_BACKEND)) {
    console.log('Mocking backend', process.env.REACT_APP_MOCK_BACKEND, (parseBool(process.env.REACT_APP_MOCK_BACKEND)));
    mockBackend(parseBool(process.env.REACT_APP_MOCK_BACKEND_ERROR_ON_NO_MATCH));
}


const rootEl = document.querySelector(selector);


if (module.hot) {
    const root = createRoot(rootEl);
    root.render(<Main/>);
} else {
    hydrateRoot(rootEl, <Main/>)
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

